import React, { useState } from "react";
// import chatPopupIcon from "../../images/chatPopupIcon.png";
// import ChatPopup from "../../Components/ChatPopup";
import moment from "moment";
import TopSection from '../../Components/InvestorLimitedpartnerDetail/TopSection'
import Layout from '../../Components/InvestorLayout'
// import Card from '../../Components/RE/DealDetail/Card'
import RefineSearch from '../../Components/RefineSearchComponent'
import FundCompanyDetail from '../../Components/InvestorFundDealDetails/FundCompanyDealDetails'
import InvestorFundRefineSearch from '../../Components/RefineSearch/InvestorFundRefineSearch'
import useModule from '../../hooks/useModule'
import { routeNames } from '../../routeSegments'

const DealPage = ({menu}) => {
  // const [showModal, setShowModal] = React.useState(false);
  // const [showPopup, setShowPopup] = useState(false);
  const [date, setDate] = useState([
    moment().subtract(12, "months"),
    moment().startOf("second"),
  ]);
  const {isPE} = useModule()
  return (
    <div>
      <Layout>
        <div className="flex h-screen flex-col">
          {/* <Card />  */}
           <TopSection />
          <div className="flex min-h-0 flex-1 overflow-hidden">
            {/* Primary column */}
            <section
              className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
            >
              <FundCompanyDetail menu={menu}/>
               
            </section>

            {/* Secondary column (hidden on smaller screens) */}
            <aside className="order-first block flex-shrink-0">
              <div className="relative flex h-full flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                {/* Your content */}
                <RefineSearch>
                <InvestorFundRefineSearch customPathname={isPE ? routeNames.pe_investors_pe_vc_funds : routeNames.vc_investors_vc_funds} />
                </RefineSearch>
              </div>
            </aside>
          </div>
        </div>
        {/* <br /> */}
        
      </Layout>
    </div>

  )
}

export default DealPage
