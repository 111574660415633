import React, { useContext, useState, Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Link, useParams, useLocation, useSearchParams } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import NotificationComponent from '../../Utils/Notification'
import { Dialog, Transition } from '@headlessui/react'
import { CompareCompanyContext } from '../../Contexts/CompareCompanyContext'
import SearchImg from '../../images/Shape_13/png/Shape.png';
import axios from 'axios'
import { compareCompany, compareCompanyList, compareInvestor, compareAdvisor ,compareCfs,maCompareCompany,maCompareAcquirer,maCompareAdvisor} from '../../Config/config'
import Search from '../../images/Shape_13/png/Shape.png'
import '../../Components/HeaderComponent.css'
import { SearchMenuContext } from "../../Contexts/SearchMenuContext";
import DebounceSelect from '../../Components/shared/DebounceSelect';
import { UserContext } from '../../Contexts/UserContext';
import useModule from "../../hooks/useModule";
import { CompareSearch } from '../../Pages/ComparePopUp';
import Constants from '../../Constants';
import { toast } from 'react-toastify';


/* The above code is a React component called `CompanyCompareSearch`. It is responsible for rendering a
search input field for comparing companies. */
const CompanyCompareSearch = () => {
    const { companyDetail, setCompanyDetail, setCompanyHeader, compareCompanyId, setCompareCompanyId, currentPage } = useContext(CompareCompanyContext);
    console.log(companyDetail, "1234567890")
    const { companyId } = useParams();
    let companyProfileId = parseInt(companyId)
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [CompanyId, setCompanyId] = useState(null)
    const [showNotification, setShowNotification] = useState(false)
    const PEHeaderSearchMenu = useContext(SearchMenuContext);
    const [FilteredData, setFilteredData] = useState(PEHeaderSearchMenu.data);
    const [compareCompanyApiStatus, setCompareCompanyApiStatus] = useState(false)
    const [popOver, setPopOver] = useState(false);
    const { getToken,logout } = useContext(UserContext);
    const { primaryMasterId,isMA } = useModule();
    const { search } = useLocation();
    const [searchParam, setSearchParam] = useSearchParams();

    /**
     * The function `addCompanyDetail` makes a POST request to an API endpoint with a request body
     * based on certain conditions and updates the state with the response data.
     */
    const addCompanyDetail = (companyId) => {
        let requestBody = ''
        isMA && currentPage == "l_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "L",
        }
        :
        isMA && currentPage == "t_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "T",
        }
        :
        isMA && currentPage == "company" ?
        requestBody = {
            "company_id": companyId,
            "is_detailed": true
        }
        :
        isMA && currentPage == "acquirer" ?
        requestBody = {
            "acquirer_id": companyId,
            "is_detailed": true,
            "type":4
        }
        :
        currentPage == "l_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "L",
        }
        :
        currentPage == "t_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "T",
        }
        :
        currentPage == "company" ?
        requestBody = {
            "company_id": companyId,
            "is_detailed": true
        }
        :
        currentPage == "cfs"?
        requestBody = {
            "company_id": companyId,
            "is_detailed": true
        }

        :
        requestBody = {
            "investor_id": companyId,
            "is_detailed": true
        }
        axios(`${isMA && currentPage == "company" ? maCompareCompany : isMA && currentPage == "acquirer" ? maCompareAcquirer : isMA && currentPage == "l_advisor" || isMA && currentPage == "t_advisor" ? maCompareAdvisor : currentPage == "company" ? compareCompany :currentPage == "cfs"? compareCfs : currentPage == "investor" ? compareInvestor : compareAdvisor}`, {
            method: "POST",
            data: requestBody,
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                if (response.data.message == "Success") {
                    setCompanyHeader(response.data.compareData.header)
                    setCompanyDetail(current => [...current, response.data.compareData.data])
                    console.log(response.data.compareData.data, "addCompanyDetail");
                }
            })
            .catch((error) => {
                if (error?.response?.status == Constants.sessionTerminatedStatus 
                    || error?.response?.status == Constants.InvalidTokenStatus
                ){
                    toast.error(`Session Expired!`);
                    setTimeout(() => logout(), 1000)
                  }
                console.log("API not working - compare company ", error);
            });
    }
    console.log(companyDetail, "addCompanyDetail");

   /**
    * The function `addCompareCompany` toggles the value of the `open` variable.
    */
    const addCompareCompany = () => {
        setOpen(!open)
    }
    const addCompanyOnchange = (event) => {
        setValue(event.target.value)
    }


    /**
     * The function `onselect` adds a company detail to an array and updates the state with the
     * selected company's ID.
     */
    const onselect = (value) => {
        addCompanyDetail(value)
        setCompareCompanyId(oldArray => [...oldArray, value])
    }

    console.log("oldArrayoldArrayoldArray", compareCompanyId);


    /**
     * The function fetchOptions makes an asynchronous request to a server using axios and returns a
     * list of options based on the response data.
     * @returns The function `fetchOptions` returns a promise that resolves to an array of objects with
     * `value` and `label` properties. If the response status is not 200, an empty array is returned.
     */
    const fetchOptions = async (value) => {
        try{
        const response = await axios(`${compareCompanyList}`, {
            method: "POST",
            data: {
                "type": searchParam.get('type'),
                "module_type": currentPage,
                "name": value,
                selected_id: compareCompanyId,
            },
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
        if (response.status === 200) {
            return response.data?.data?.map((arr, i) => {
                return { value: arr.id, label: arr.entity_name };
            });
        } else {
            return []
        }
    }catch(error){
        if (error?.response?.status == Constants.sessionTerminatedStatus) {
            toast.error(`Session Expired!`);
            logout()
          }
    }
    }
    

    return (

        <div
            className="relative pointer-events-auto border rounded border-[#BC8B20] p-1 ">
            {showNotification ? <NotificationComponent message="Company Added Successfully" /> : ""}
            <CompareSearch currentPage={currentPage} fetchOptions={fetchOptions} onselect={onselect} open={open} setOpen={setOpen} />

            <label onClick={addCompareCompany} className="flex text-sm rounded cursor-text">
                <img alt='Search' className="h-[16px] w-[16px] ml-.5"
                    src={SearchImg} />
                <div className={`bg-[#F3F4F6] focus:outline-none text-slate-400 w-[${currentPage == "t_advisor" ? '13rem' :'11rem'}] placeholder:tracking-[-0.3px] text-[12px] font-sans_book_body leading-[14px]`}>
                    <p className='ml-1'>{currentPage == "company" ? PEHeaderSearchMenu.companyPlaceholder :currentPage == "cfs"? PEHeaderSearchMenu.cfscompanyPlaceholder : currentPage == "acquirer" ? PEHeaderSearchMenu.acquirerPlaceHolder :currentPage == "investor" ? PEHeaderSearchMenu.investorPlaceholder : currentPage == "l_advisor" ? PEHeaderSearchMenu.ladvisorPlaceholder : currentPage == "t_advisor" ?PEHeaderSearchMenu.tadvisorPlaceholder : ""}</p>
                </div>

                {/* <input
                    onClick={addCompareCompany} className="bg-[#F3F4F6] focus:outline-none text-slate-400 w-[11rem] placeholder:tracking-[-0.3px] text-[12px] font-sans_book_body leading-[14px]"
                    placeholder={currentPage == "company" ? PEHeaderSearchMenu.companyPlaceholder : currentPage == "investor" ? PEHeaderSearchMenu.investorPlaceholder : currentPage == "l_advisor" ? PEHeaderSearchMenu.ladvisorPlaceholder : PEHeaderSearchMenu.tadvisorPlaceholder}
                    type="text"
                    
                    name="search" /> */}
            </label>

        </div>

    )
}

export default CompanyCompareSearch