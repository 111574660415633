import { useParams } from "react-router";
import { useLatestFinancialHighlights } from "../DetailPage/FinancialComponents/ratios";

export const  useCard = (data) => {
    // 1. Calculate Total Funding
    const totalFunding = data?.private_equity?.pe_investments?.reduce((total, investment) => total + parseFloat(investment.amount), 0);

   // 2. Find Latest Round
    const latestRoundDate = data?.private_equity?.pe_investments?.reduce((latestDate, investment) => {
        const currentDate = new Date(investment.deal_date);
        return currentDate > latestDate ? currentDate : latestDate;
    }, new Date(0));

    // Format date to MMM-YYYY
    const latestRound = latestRoundDate?.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });

    // 3. Calculate Total Exits
    const totalExits = data?.private_equity?.pe_exits?.reduce((total, exit) => total + parseFloat(exit.amount), 0).toFixed(2);

    // 4. Count Number of Investors
    const investorsSet = new Set();
    data?.private_equity?.pe_investments?.forEach(investment => {
    investment.investors.forEach(investor => {
        if(investor.id !== 9){
            investorsSet.add(investor.id);
        }
    });
    });

    const numberOfInvestors = investorsSet.size;

    // 5. To get the latest revenue
    const latest = useLatestFinancialHighlights(data)

    // 6. Count the acquirer and acquistions
    const acquirerSet = new Set();
    const acquisitionSet = new Set();
    const {companyId} = useParams();
    const cmpId = parseInt(companyId);

    data?.merger_acquisition?.forEach(item => {
        if (cmpId !== item.company.id) {
            acquisitionSet.add(item.company.id);
        } else acquirerSet.add(item.acquirer.name);
        
    });

    const numberOfAcquirers = acquirerSet.size;
    const numberOfAcquisitions = acquisitionSet.size;

    // New object with calculated values
    const calculatedValues = {
        totalFunding: totalFunding?.toFixed(2),
        latest_round: latestRound,
        totalExits,
        no_of_investors: numberOfInvestors,
        revenue: latest.total_income,
        numberOfAcquirers,
        numberOfAcquisitions
    };

    return calculatedValues;
}