import React from 'react'
import Card from '../../Components/MA/DetailCard/Card'
import MACompanyDetail from '../../Components/MA/DetailPage/MACompanyDetail'
import RefineSearch from '../../Components/RE/RefineSearchComponent'
import MaCompaniesRefineSearch from '../../Components/RefineSearch/MaCompaniesRefineSearch'
import { routeNames } from '../../routeSegments'

/**
 * The DetailPage function returns a React component that displays a card, a main section with company
 * details, and a sidebar for refining search.
 * @returns The DetailPage component is returning a JSX element.
 */
function DetailPage() {
    return (
        <div className="flex h-screen flex-col">
            <Card />
            <div className="flex min-h-0 flex-1 overflow-hidden">
                <section
                    aria-labelledby="primary-heading"
                    className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                    <MACompanyDetail />
                </section>
                {/* Secondary column (hidden on smaller screens) */}
                <aside className="order-first block flex-shrink-0">
                    <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                        <RefineSearch>
                            <MaCompaniesRefineSearch customPathname={routeNames.ma_companies}/>
                        </RefineSearch>
                    </div>
                </aside>
            </div>
            <br />

        </div>
    )
}

export default DetailPage
