import React from 'react'
import ReLayout from '../../Components/ReLayout'
import InvestorCompanyDetail from '../../Components/REInvestor/InvestorDetailPage/InverstorCompanyDetail'
import RefineSearch from '../../Components/RefineSearchComponent'
import ReInvestorDetailCard from './Investor/components/ReInvestorDetailCard'
import Constants from '../../Constants'
import { useSearchParams } from 'react-router-dom'
import ReAdvisorRefineSearch from '../../Components/RefineSearch/ReAdvisorRefineSearch'
import { routeNames } from '../../routeSegments'
import ReInvestorExitRefineSearch from '../../Components/RefineSearch/ReInvestorExitRefineSearch'
/**
 * The ReInvestorDetail function is a React component that renders a layout with a primary column and a
 * secondary column, and includes a card and a refine search component.
 * @returns The ReInvestorDetail component is returning a JSX element, which represents the structure
 * and content of the component's rendered output.
 */
const ReInvestorDetail = () => {
    const [URLSearchParams, SetURLSearchParams] = useSearchParams({ section: Constants.primaryMasterSubIds.re_investor_investment_id })

    const section = parseInt(URLSearchParams.get('section'))

    console.log(section,"sectionss");

  return (
    <div>
        <ReLayout>
            <div className="flex h-screen flex-col">
                    {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
                    <ReInvestorDetailCard />
                    <div className="flex min-h-0 flex-1 overflow-hidden bg-[#EDEDED] ">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto  lg:order-last">
                            {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
                            <InvestorCompanyDetail menu="re" section={section}/>
                            
                        {/* <Outlet /> */}
                        {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="order-first block flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                              <RefineSearch>
                                  {
                                      section === Constants.primaryMasterSubIds.re_investor_investment_id
                                          ? <ReAdvisorRefineSearch customPathname={routeNames.re_investors_re_investments} />
                                          : section === Constants.primaryMasterSubIds.re_investor_exits_id
                                              ? <ReInvestorExitRefineSearch customPathname={routeNames.re_investors_exists} />
                                              : section === Constants.primaryMasterSubIds.re_investor_ipo_id
                                                  ? <ReInvestorExitRefineSearch customPathname={routeNames.re_investors_backed_ipos} />
                                                  : <ReAdvisorRefineSearch customPathname={routeNames.re_investors_re_investments} />

                                  }
                              </RefineSearch>
                            </div>
                        </aside>
                    </div>                   
                </div>
            </ReLayout>
    </div>
  )
}

export default ReInvestorDetail
