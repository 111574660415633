import React from 'react'
import Layout from '../Components/Layout'
import Card from '../Components/DetailPageCard/NewCard'
import CompanyDetail from '../Components/DetailPage/NewCompanyDetail'
import RefineSearch from '../Components/RefineSearchComponent'
import { COMPANY_PROFILE, DEATAILPAGE_CARD } from '../Queries/Pe'
import { routeNames } from '../routeSegments'
import InvestmentRefineSearch from '../Components/RefineSearch/InvestmentRefineSearch'
import ExitRefineSearch from '../Components/RefineSearch/ExitRefineSearch'
import BackedIPORefineSearch from '../Components/RefineSearch/BackedIPORefineSearch'
import AngelRefineSearch from '../Components/RefineSearch/AngelRefineSearch'
import IncubationRefineSearch from '../Components/RefineSearch/IncubationRefineSearch'
import CurrencyContextProvider from '../Contexts/CurrencyContext';
import { useLocation } from 'react-router'
import { useSearchParams } from 'react-router-dom'
import Constants from '../Constants'

/**
 * The DetailPage function is a React component that renders a page with a layout, a primary column,
 * and a secondary column, and includes various refine search components based on the section
 * parameter.
 * @returns The DetailPage component is returning a JSX element.
 */
function DetailPage({  menu }) {
    const { state} = useLocation();
    const [URLSearchParams] = useSearchParams({ section: 1 })

    let filterPath = state?.prevPath ?? null;

    if (filterPath == null) {
        if (menu == "pe") {
            filterPath = routeNames.pe_companies_pe_vc_investments;
        } else {
            filterPath = routeNames.vc_companies_vc_investments
        }
    }

    const section = URLSearchParams.get('section');


    return (
        <CurrencyContextProvider>
            <div>
                <Layout>
                    <div className="flex h-screen flex-col">
                        <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD} />
                        <div className="flex min-h-0 flex-1 ">
                            {/* Primary column */}
                            <section
                                aria-labelledby="primary-heading"
                                className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last">
                                <CompanyDetail menu={menu} COMPANY_PROFILE={COMPANY_PROFILE} section={section}/>

                                {/* <Outlet /> */}
                                {/* Your content */}
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="order-first block flex-shrink-0">
                                <div className="relative flex h-full  flex-col overflow-hidden border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch>
                                        {
                                            section == Constants.primaryMasterSubIds.pe_vc_investments_id
                                                ? <InvestmentRefineSearch customPathname={filterPath} />
                                                : section == Constants.primaryMasterSubIds.pe_vc_exits_id || section == Constants.primaryMasterSubIds.pe_vc_pm_id || section == Constants.primaryMasterSubIds.pe_vc_ma_id
                                                    ? <ExitRefineSearch typeId={parseInt(section)} customPathname={filterPath} />
                                                    : section == Constants.primaryMasterSubIds.pe_vc_backed_ipos_id
                                                        ? <BackedIPORefineSearch customPathname={filterPath} />
                                                        : section == Constants.primaryMasterSubIds.pe_vc_angel_invesments_id
                                                            ? <AngelRefineSearch customPathname={filterPath} />
                                                            : section == Constants.primaryMasterSubIds.pe_vc_incubation_id
                                                                ? <IncubationRefineSearch customPathname={filterPath} />
                                                                : <InvestmentRefineSearch customPathname={filterPath} />
                                        }
                                    </RefineSearch>
                                </div>
                            </aside>
                        </div>

                        {/* <div className="fixed  right-1 bottom-0">          
                    <img className="float-right w-[4rem] h-[4rem] cursor-pointer" src={chatPopupIcon} /> 
                    </div>  */}
                        <br />
                    </div>
                </Layout>
            </div>

        </CurrencyContextProvider>
    )
}

export default DetailPage
