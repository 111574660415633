import React, { useState, useContext, useEffect, useRef } from "react";
import axios from "axios";
import { getNotification, markAsReadNotification, } from "../../../src/Config/config";
import { useNavigate } from "react-router-dom";
import { getDetailPageUrl } from "../../Utils/utils";
import Notifications from ".././NotificationSettings/NotificationsSetting";
import Pusher from "pusher-js";
import { UserContext } from "../../Contexts/UserContext";
import { HeaderMenuContext } from "../../Contexts/HeaderMenuContext";
import Constants from "../../../src/Constants";
import { toast } from "react-toastify";

const BellNotification = () => {
  const [notificationData, setNotificationData] = useState({});
  const [notificationDataCount, setNotificationDataCount] = useState('');
  const [showNotification, setShowNotification] = useState(false);
  const { getToken, logout } = useContext(UserContext);
  const { notificationCount, setNotificationCount } = useContext(HeaderMenuContext)

  const navigate = useNavigate();

  useEffect(() => {
    axios(`${getNotification}`, {
      method: "POST",
      data: {},
      headers: {
        "Content-Type": "application/json",
        Authorization: getToken(),
      }, // console.log('Incremental Issue', i+1);
    })
      .then((response) => {
        console.log(response?.data, "Notification Response");

        setNotificationData(response.data);
        setNotificationDataCount(response.data.unread_count);
        setNotificationCount(response.data.unread_count)
        // if (response?.data?.message == "Success") {
        //     // 
        // }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          toast.error(`Session Expired!`);
          setTimeout(() => logout(),1000)
        }
        console.log("API not working", error);
      });
  }, [notificationCount]);

 /**
  * The function `notificationPanel` toggles the visibility of the notification panel.
  */
  const notificationPanel = () => {
    // if (notificationDataCount > 0) {
    //   setShowNotification(!showNotification)
    // }
    // else {
    //   toast.warning(`You have no notifications `, { position: 'bottom-right' })

    // }
    setShowNotification(!showNotification)
  }



  useEffect(() => {
    const channelName = `private-App.Models.AuthUsers.${localStorage.getItem("user_id")}`
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
      cluster: process.env.REACT_APP_PUSHER_CLUSTER,
      authEndpoint: process.env.REACT_APP_BROADCAST,
      auth: {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      },
    });

    const channel = pusher.subscribe(channelName);

    channel.bind(
      "Illuminate\\Notifications\\Events\\BroadcastNotificationCreated",
      (data) => {
        const audio = new Audio('/sound/ping.mp3');
        audio.play();
        console.log("New notification received:", data);
        setNotificationData((prevState) => ({
          ...prevState,
          unread: [...prevState.general.unread, data],
        }));
        setNotificationDataCount((prevState) => prevState + 1);
        toast.success(data?.message, { position: 'bottom-right' })
        // showNotificationBox(data?.message)
      }
    );

    return () => {
      pusher.unsubscribe(channelName)
    }
  }, []);

 /**
  * The function `handleNotificationClick` handles the click event on a notification and performs
  * different actions based on the `redirectionType` parameter.
  * @param entity - The `entity` parameter represents the notification entity that was clicked. It
  * contains information about the notification, such as its ID, URL, and file name.
  * @param redirectOption - The `redirectOption` parameter is used to determine the type of redirection
  * that should occur when the notification is clicked. It can have three possible values: "alert",
  * "request", or "general".
  * @param redirectionType - The `redirectionType` parameter is a string that determines the type of
  * redirection to be performed based on the notification. It can have one of the following values:
  */
  function handleNotificationClick(entity, redirectOption, redirectionType) {
    let headerData = {
      "Content-Type": "application/json",
      Authorization: getToken(),
    };
  
    if (redirectionType === "general") {
      headerData.responseType = "blob";
    }
  
    axios(`${markAsReadNotification}`, {
      method: "POST",
      data: {
        id: entity.id,
      },
      headers: headerData,
      responseType: "blob", // Specify the responseType here as well
    })
      .then((response) => {
        setNotificationData((prevState) => {
          const updatedUnRead = prevState.unread && prevState.unread.filter(
            (msg) => msg.id !== entity.id
          );
          return { ...prevState, unread: updatedUnRead };
        });
        setNotificationDataCount((prevState) => prevState - 1);
  
        if (redirectionType === 'alert') {
          navigate(entity.url);
        } else if (redirectionType === 'request') {
          navigate(`/raise-request/${entity.entity_id}`);
        } else if (redirectionType === 'general') {
          // Create a Blob object from the response data
          const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          // Create a temporary URL for the Blob
          const url = window.URL.createObjectURL(blob);
          // Create a link element and set its properties
          const link = document.createElement('a');
          link.href = url;
          link.download = entity.file_name;
          // Append the link element to the document body
          document.body.appendChild(link);
          // Trigger the click event on the link element to start the download
          link.click();
          // Cleanup by removing the link element and revoking the Blob URL
          document.body.removeChild(link);
          window.URL.revokeObjectURL(url);
        }
      })
      .catch((error) => {
        console.error('Error occurred during the message reading process:', error);
      });
  }

  useEffect(() => {
    setNotificationCount(notificationDataCount)
  }, [notificationDataCount, notificationCount])

  return (
    <button
      className="cursor-cursor pt-1"
      onClick={notificationPanel}
    // disabled={notificationDataCount <=0}
    >
      <span className="relative text-[#666666] md:flex">
        <svg
          className={notificationDataCount > 0 ?"h-6 w-6":"h-7 w-7"}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
          />
        </svg>
        {notificationDataCount >0 &&
        <span className="absolute top-[2px] md:left-[2px] inline-flex items-center justify-center h-1 w-1 p-2 text-xs font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-[#BC8B21] rounded-full" style={{ right: '6px' }}>        {notificationDataCount}
        </span>}
      </span>

      {showNotification && (
        <div>
          <Notifications
            notificationData={notificationData}
            notificationDataCount={notificationDataCount}
            showNot={showNotification}
            setShowNot={setShowNotification}
            handleNotificationClick={
              handleNotificationClick
            }
          />{" "}
        </div>
      )}
    </button>
  )
}

export default BellNotification
