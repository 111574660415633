import { Fragment, useState, useEffect, useContext } from 'react'
import { createNotesApi, notesApi, updateNotesApi } from '../../Config/config'
import axios from 'axios'
import edit from "../../images/combined-shape.png";
import { Dialog, Transition } from '@headlessui/react'
import MyStatefulEditor from "../../Components/NotesPopup/Rtetest"
import "./Notes.css"
import ConfirmPopup from '../../Utils/ConfirmPopup';
import { toast } from 'react-toastify';
import { Tooltip } from 'antd';
import PrimaryButton from '../shared/PrimaryButton';
import OutlineButton from '../shared/OutlineButton';
import { UserContext } from '../../Contexts/UserContext';
import useModule from '../../hooks/useModule';
import {getTextAlignStyles} from "react-rte/lib/lib/blockStyleFunctions";
import Constants from '../../Constants';

/**
 * The `NotesPopup` component that renders a popup window for adding or
 * editing notes.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `NotesPopup` component. These properties can be accessed using dot notation, such as `props.type_id`
 * and `props.cid`.
 */
const NotesPopup = (props) => {

  const [open, setOpen] = useState(false)
  const [val, setVal] = useState('');
  const [rawValue, setrawValue] = useState()
  const [showEdit, setShowEdit] = useState(false)
  const [savedNote, setsavedNote] = useState();
  const [refreshCount, setrefreshCount] = useState(0)
  const [alert, setalert] = useState({ message: "", show: false });
  const [isSaving, setIsSaving] = useState(false);
  const {getToken, logout} = useContext(UserContext);
 const{primaryMasterId}= useModule();
  const onChange = (value) => {
    // console.log("valuevaluevaluevaluevalue", value?.getEditorState()?.getCurrentContent()?.hasText(), !!value.getEditorState().getCurrentContent().getPlainText().trim());
    setVal(value.toString("html",{
      blockStyleFn: getTextAlignStyles,
   }));
    setrawValue(value)
  };



  const validate = () => {
    let isEmpty = !!rawValue?.getEditorState()?.getCurrentContent()?.getPlainText()?.trim();
    if (!isEmpty) {
      setalert({ show: true, message: "Notes must not be empty", closeBtnName: "OK", isAlertOnly: true });
      return false;
    }
    return true;
  }

  useEffect(() => {
    axios.post(notesApi, {
      "noteable_id": props.type_id,
      "noteable_sub_type_id": props.cid,
      primary_type_master_id:primaryMasterId,
    },{
      headers:{
        "Authorization": getToken()
    }
    }).then((response) => {
      // console.log("responseresponseresponse", response.data,response.request);
      if (response.status === 200) {
        setsavedNote(response.data)
      }
    }).catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
            toast.error(`Session Expired!`);
            setTimeout(() => logout(), 1000)
        }
    });
  }, [props.type_id, props.cid, refreshCount])

  // const editNote = () => {
  //   setActiveBtn(!activeBtn)    

  // } 
  const onClose =()=>{
    setalert({ show: true, message: "Are you sure you want to leave?" })
    // setActiveBtn(!activeBtn)
  }
  const postNote = () => {
    if(savedNote?.method === "update"){
    // setActiveBtn(!activeBtn)
  }
    if (!validate()) {
      return;
    }    
    setIsSaving(true);
    axios(savedNote?.method === "update" ? updateNotesApi : createNotesApi, {
      method: "POST",
      data: {
        "noteable_id": props.type_id,
        "noteable_sub_type_id": props.cid,
        "content": val,
        primary_type_master_id:primaryMasterId,
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        setOpen(!open);
        setIsSaving(false);
        // console.log(response, 'created Note response');     
        if (response?.data?.success === true) {
          setrefreshCount(prev => prev + 1)
          toast.success(response?.data?.message)
        }
        else {
          
          toast.warning(response?.data?.message)
        }
      })

      .catch((error) => {
        console.log("API not working", error);
      });
    

  }


  // const isEditNoteClose = () => {
  //   setalert({ show: true, message: "Are you sure you want to leave?" });
  // }
  const isEditNoteOpen = () => {
    setOpen(!open)
    setShowEdit(true)
  }

  const onConfirmation = (flag) => {
    if (flag === 1) {
      setOpen(!open)
    } else {

    }

    setalert({ show: false, message: "" });
  }


  return (
    <div>
      
      {<ConfirmPopup {...alert} onChange={onConfirmation} />}

      <Tooltip title="Add Notes">
      <button className="z-10" type="button" aria-labelledby="modal-title" role="dialog" aria-modal="true" >
        <img
          className={open ? "w-[22px] h-[22px]  share-btn-color" : "w-[22px] h-[22px] brightness-0 invert-[.6] "}
          src={edit} onClick={isEditNoteOpen}
          alt=""
        />
      </button>
      </Tooltip>
      {showEdit &&
        <Transition.Root show={open} as={Fragment}    onClose={() => null} >
          <Dialog as="div" className="relative z-10" 
          onClose={() => null}
          onClick={() => setOpen(false)}
          >
            <Transition.Child
              as={Fragment}
              onClose={() => null}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <div className="fixed inset-0  z-10 overflow-hidden	">
              <div className="lg:mt-[8rem] md:mt-[11rem] mt-[11rem] min-h-full  p-4  ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="addnote rounded-lg bg-white  shadow-xl  md:ml-[4.75rem] md:mr-[2.5rem] lg:ml-[7.25rem] lg:mr-[3.25rem]"  >
                    <div className='flex pt-3  py-1 justify-between px-4'>
                      <div className=' px-5 md:px-0 pt-1 text-lg font-medium leading-6 text-gray-900 '>
                        {savedNote?.method === "update" ? "Update Notes" : "Add Notes"}
                      </div>
                      <div className='flex space-x-3 pt-1'>
                        <div>
                          <button type="button"
                            onClick={onClose}
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none ">
                            <span className="sr-only">Close</span>
                            <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="text-left py-3 px-4 ">
                      <MyStatefulEditor className="wrapper-class" markup={savedNote?.content ?? ""} onChange={onChange} 
                      // data={savedNote?.method === "update"? activeBtn:!activeBtn}
                      />
                    </div>
                    <div className='bg-gray-50 py-3 flex flex-row-reverse px-6 z-[1] relative'>
                     
                      <PrimaryButton
                      onClick={()=>postNote()}
                      isLoading={isSaving}
                      disable={isSaving}>
                        
                        {savedNote?.method === "update"
                          ? isSaving ? "Updating..." : "Update"
                          : isSaving ? "Saving..." : "Save"}
                      </PrimaryButton>
                      <OutlineButton onClick={onClose}>
                        Close
                      </OutlineButton>
                      {/* <button type="button"
                        onClick={() => { postNote() }}
                        disabled={savedNote?.method === "update"?activeBtn:!activeBtn}
                        className={"inline-flex w-full justify-center rounded-md font-sans_bold_body px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-offset-2 ml-3 sm:w-auto sm:text-sm " 
                        +(savedNote?.method === "update" ? 
                        (!activeBtn ? "text-white bg-[#BC8B20] border border-transparent hover:bg-[#a37517]":"text-gray-700 bg-[white] border border-gray-300" )
                        : " text-white bg-[#BC8B20] border border-transparent hover:bg-[#a37517]")
                        }>
                          {savedNote?.method === "update" ? "Update" : "Save"}
                          </button> */}
                          {/* {savedNote?.method === "update" ?
                          <button type="button"
                            onClick={() => { editNote() }}
                            disabled={savedNote?.method === "update"?!activeBtn:activeBtn}
                            className={"inline-flex w-full justify-center rounded-md font-sans_bold_body font-medium px-4 py-2 text-base font-medium shadow-sm  focus:outline-none focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm " +(activeBtn ? "text-white bg-[#BC8B20] border border-transparent hover:bg-[#a37517]":"text-gray-700 bg-[white] border border-gray-300" )}>
                            Edit
                          </button>:''
                          } */}
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>

            </div>
          </Dialog>
        </Transition.Root>
      }
    </div>
  )
}

export default NotesPopup;
