import axios from 'axios';
import React, { useContext, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { filterLocationUrl } from '../../Config/config';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import { TableHeaderContext } from '../../Contexts/TableHeaderContext';
import { UserContext } from '../../Contexts/UserContext';
import AcquirerDealRangeFilter from '../RefineSearchComponents/AcquirerDealRangeFilter';
import CityFilter from '../RefineSearchComponents/CityFilter';
import SubsectorFilter from '../RefineSearchComponents/SubSector';
import Constants from '../../Constants';
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Investor from "../RefineSearchComponents/Investor";
import Acquirers from "../RefineSearchComponents/Acquirers";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

/**
 * The function `ReInvestorExitRefineSearch` is a React component that renders a form for refining
 * search filters and applying them to a custom pathname.
 * @returns a JSX element, which represents the UI of the component.
 */
function ReInvestorExitRefineSearch({ customPathname, dataPath }) {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = useLocation(); 
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false
    const { setrefineSearchFilters, onErrorHandler, refineSearchFilters, MinAcquirerDealRange, MaxAcquirerDealRange ,setMonthPickerValue , SwitchState,setSwitchState,
        InvestorOptions ,dbDropdown , setDbDropdown , setInvestorOptions ,AcquirersOptions , setAcquirersOptions ,
        lAdvisorsOptions,setlAdvisorsOptions,isMobile,setOpen,open,tAdvisorsOptions , settAdvisorsOptions , TargetCompanyOptions , setTargetCompanyOptions ,dropdown} = useContext(PeFilterContext);
    const { setSelectedPage } = useContext(TableHeaderContext);
    const { getToken } = useContext(UserContext);
    const [select, setSelected] = useState(0);

    const [SubSectorOptions, setSubSectorOptions] = useState([])
    const [MinDealRangeOptions, setMinDealRangeOptions] = useState([])
    const [MaxDealRangeOptions, setMaxDealRangeOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])


  /**
   * The function `applyFilter` updates the refine search filters based on the selected subsector,
   * cities, and deal range options.
   */
    const applyFilter = () => {
        const selectedSubsector = SubSectorOptions?.filter(option => option.isChecked);
        const selectedCities = CityOptions?.filter(option => option.isChecked);
        const minDealRange = MinDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const maxDealRange = MaxDealRangeOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const selectedOperators = SwitchState;
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedInvestor = InvestorOptions?.filter(option => option.isChecked); 
        const selectedAcquirers = AcquirersOptions?.filter(option => option.isChecked); 

        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                dealRange: minDealRange != null && maxDealRange != null ? [minDealRange, maxDealRange] : [],
                subSector: selectedSubsector,
                city: selectedCities,
                deals_operator : selectedOperators,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                investor_id : customPathname.includes("/re/advisors/re-investments") || customPathname.includes("/re/transaction-advisors/other-ma") ? selectedAcquirers  :  selectedInvestor ,
                target_company:selectedTargetCompany,
            },
        }))
    }

   /**
    * The function `resetFilter` resets a specific filter in the `refineSearchFilters` state object by
    * setting its value to an empty object.
    */
    const resetFilter = () => {
        
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setTargetCompanyOptions([]);
        setInvestorOptions([]);
        setAcquirersOptions(AcquirersOptions.map(option => {
            if (option.isChecked) {
                return { ...option, isChecked: false };
            }
            return option;
        })
    )
    }


    // Submitting Refine Search Form 
  /**
   * The function `refineSearchFilter` updates search filters, resets certain values, and applies the
   * filter.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. It contains information about the event, such as the type of event, the target element,
   * and any additional data associated with the event. In this case, the event is being used to
   * prevent the default behavior of a form submission
   */
    const refineSearchFilter = (event) => {
        if(isMobile){
            setOpen(!open);
        }
        event.preventDefault();
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        setSelectedPage(1);
        applyFilter();
        setSelected(0)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

  /**
   * The `onResetClick` function resets the selected page, resets the filter, and navigates to a custom
   * pathname if the current pathname is not already the custom pathname.
   * @param event - The event parameter is an object that represents the event that triggered the
   * function. In this case, it is the click event that occurred when the user clicked on a reset
   * button or element.
   */
    const onResetClick = (event) => {
        event.preventDefault();
        setSelectedPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

  /**
   * The function `accordionClick` sets the selected value to 0 if it is equal to `select`, otherwise
   * it sets it to the provided value.
   * @param val - The `val` parameter is a value that is passed to the `accordionClick` function. It is
   * used to determine whether the accordion should be selected or deselected.
   */
    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    useEffect(() => {

        const cityIds = refineSearchFilters[customPathname]?.city?.map(value => value.id) ?? [];

        axios.post(filterLocationUrl, {
            country: [],
            region: [],
            state: [],
            city: [],

        }, {
            headers: {
                "Authorization": getToken()
            }
        }).then(response => {
            if (response.status === 200) {
                setCityOptions(response.data.city?.map(option => ({ ...option, isChecked: cityIds.includes(option.id) })))
            }
        }).catch(onErrorHandler)
    }, [refineSearchFilters])


    const toggleSwitchState = () => {
        setSwitchState(prevState => prevState === "and" ? "or" : "and");
    };

    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };

    return (
        <div className="h-screen">
            <div className="flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter">
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                            <AcquirerDealRangeFilter
                                customPathname={customPathname}
                                MinDealOptions={MinDealRangeOptions}
                                setMinDealOptions={setMinDealRangeOptions}
                                MaxDealOptions={MaxDealRangeOptions}
                                setMaxDealOptions={setMaxDealRangeOptions}
                                MinDealRange={MinAcquirerDealRange}
                                MaxDealrange={MaxAcquirerDealRange} />
                            <SubsectorFilter
                                SubSectorOptions={SubSectorOptions}
                                setSubSectorOptions={setSubSectorOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(2)}
                                open={select === 2}
                                dataPath={dataPath}
                            />
                            <CityFilter
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(6)}
                                label="City (of Target Company)"
                                open={select === 6} />

                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                            <h1 className='font-bold text-[16px] ml-1'>{(customPathname.includes("/re/transaction-advisors/") || customPathname.includes("/re/advisors/") ? "SHOW ADVISORS BY" : "SHOW INVESTORS BY")}</h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>

                                {/* <div className="py-1">
                                    <ToggleButton value={SwitchState} onLabel="AND" offLabel="OR" onToggle={toggleSwitchState} />
                                </div> */}

                                { (customPathname.includes("/re/advisors/other-ma") || customPathname.includes("/re/transaction-advisors/other-ma") ) ? (
                                    <Acquirers 
                                    options={AcquirersOptions}
                                    setOptions={setAcquirersOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(17)}
                                    label="Acquirers"
                                    open={select === 17 }
                                />
                                ) : (
                                    <Investor 
                                    options={InvestorOptions}
                                    setOptions={setInvestorOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(32)}
                                    label="Investor"
                                    open={select === 32}
                                />
                                )}
                                

                                <TargetCompany 
                                    options={TargetCompanyOptions}
                                    setOptions={setTargetCompanyOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(31)}
                                    label="Company"
                                    open={select === 31}
                                />
                                {(customPathname.includes("/re/advisors/") || customPathname.includes("/re/transaction-advisors/")) ? (
                                    <>
                                        {customPathname.includes("/re/advisors/") && (
                                            <LAdvisor 
                                                options={lAdvisorsOptions}
                                                setOptions={setlAdvisorsOptions}
                                                customPathname={customPathname}
                                                label="Legal Advisors"
                                                accordionClick={() => accordionClick(21)}
                                                open={select === 21}
                                            />
                                        )}
                                        {customPathname.includes("/re/transaction-advisors/") && (
                                            <TAdvisor 
                                                options={tAdvisorsOptions}
                                                setOptions={settAdvisorsOptions}
                                                label="Transaction Advisors"
                                                customPathname={customPathname}
                                                accordionClick={() => accordionClick(29)}
                                                open={select === 29}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <LAdvisor 
                                            options={lAdvisorsOptions}
                                            setOptions={setlAdvisorsOptions}
                                            customPathname={customPathname}
                                            label="Legal Advisors"
                                            accordionClick={() => accordionClick(21)}
                                            open={select === 21}
                                        />
                                        <TAdvisor 
                                            options={tAdvisorsOptions}
                                            setOptions={settAdvisorsOptions}
                                            label="Transaction Advisors"
                                            customPathname={customPathname}
                                            accordionClick={() => accordionClick(29)}
                                            open={select === 29}
                                        />
                                    </>
                                )}

                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
        </div>
    )
}

export default ReInvestorExitRefineSearch
