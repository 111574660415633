
import { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { sortRows, filterRows, paginateRows } from '../helpers'
import Pagination from '../Pagination'
import CompanyLogo from '../CompanyLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
import { routeNames } from '../../routeSegments';
import RandomLogo from '../RandomLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import { rowsPerPageData, maAcquirerListApi, getPEGlobalSearchCompaniesApi } from '../../Config/config'
import { TableDataContext } from '../../Contexts/TableDataContext'
import axios from "axios";
import CustomNavigation from '../../Utils/CustomNavigation';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import AddNotePopover from '../shared/AddNotePopover';
import Constants from '../../Constants';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import moment from "moment";
import { useRef } from 'react';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';


/**
 * The `Table` component is a JavaScript function that renders a table based on the provided columns,
 * menu, month, order, and orderBy parameters.
 * @returns The `Table` component is being returned.
 */
const Table = ({ columns, menu, month, order, orderBy,sub_type_master_id }) => {

  const { rows, setRows, data, setData, isLoad, setIsLoad, isDataLoad, setIsDataLoad, totalNoOfCompanies, setTotalNoOfCompanies, currency, setTableAcquirerId, currentPage, setCurrentPage,setCurrentFilter, setAllDealIds, globalSearchResult} = useContext(TableDataContext);
  const { monthPickerValue, setMonthPickerValue, isMonthPickerChanged, setIsMonthPickerChanged, isTagClosed, setIsTagClosed, refineSearchFilters,groupId} = useContext(PeFilterContext);
  const [activePage, setActivePage] = useState(currentPage)
  const [filters, setFilters] = useState({})
  const [sort, setSort] = useState({ order: order, orderBy: orderBy })
  // const [sort, setSort] = useState({ order: 'asc', orderBy: 'acquirer_name' })
  const [showId, setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  const abortRef = useRef(null)
  var location = useLocation();
  const[reload, setReload] = useState(false)

  /**
   * The function `findActivePage` sets the current page, renders a table with specified parameters,
   * and sets a flag indicating that the page is loading.
   * @param page - The "page" parameter represents the current page number.
   */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    setIsLoad(true)
  }

  console.log('refineSearchFiltersma', refineSearchFilters[location.pathname]);
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

  /* The above code is a JavaScript function that uses the `useCallback` hook. It is a table rendering
  function that makes an API call to fetch data based on the provided parameters (page, month,
  column, order). */
  const tableRender = useCallback((page, month, column, order) => {
    console.log(month, 'm usecallback month'); 

    setIsTagClosed(0)
    setIsMonthPickerChanged(0)
    axios(`${maAcquirerListApi}`, {
      method: "POST",
      signal: abortRef.current.signal,
      data:{
        from: isFromGlobalSearch() ? "gs" : "",
        date_range: isFromGlobalSearch()
          ? {
              from_date: globalSearchResult?.date_range?.from_date,
              to_date: globalSearchResult?.date_range?.to_date,
            }
          : {
              from_date: moment(month[0]).format("YYYY-MM-DD"),
              to_date: moment(month[1]).format("YYYY-MM-DD"),
            },
        "page": page,
        "order_by": { "column": column, "direction": order },
        acquirer_ids: isFromGlobalSearch() ? globalSearchResult.acquirer.count === 0 ? [0] : globalSearchResult?.acquirer?.acquirer_ids : refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
        }
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },

    })
      .then((response) => {
        if (response?.data?.message == "Success") {
          // console.log(response,'cfs table response');      
          setCurrentFilter(response.config.data) 
          setTotalNoOfCompanies(response?.data?.num_acquirers)
          setIsLoad(false)
          setIsDataLoad(false)
          // setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.companies)
          
          setData(response?.data)
          setTableAcquirerId(response?.data?.all_acquirer_ids)
          setAllDealIds(response?.data?.all_deal_ids)
          console.log(response?.data, 'cfs table response');
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          toast.error(`Session Expired!`);
          setTimeout(() => logout(), 1000)
        }
        console.log("API not working", error);
      });
  }, [refineSearchFilters]);


  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage);
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId )
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue,groupId ,reload]);

  /**
   * The addNote function toggles the visibility of a note and sets the id of the note to be shown.
   * @param id - The `id` parameter is the identifier of the note that needs to be added.
   */
  const addNote = (id) => {
    setShowNote(!showNote)
    setShowId(id)
  }

  const rowsPerPage = rowsPerPageData

  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  // const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  // const calculatedRows = paginateRows(sortedRows, currentPage, rowsPerPage)
  // console.log(calculatedRows, "cfs calculated")

  /**
   * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
   * and order.
   * @param accessor - The `accessor` parameter is a value that represents the property or key of the
   * object that you want to sort by. It is used to identify the specific property or key that you want
   * to sort the data by.
   * @param order - The "order" parameter is used to specify the sorting order. It can have two
   * possible values: 'asc' for ascending order and 'desc' for descending order.
   */
const handleSort = (accessor) => {
  setCurrentPage(currentPage); // Assuming currentPage is defined elsewhere
  setRows([]);
  setIsLoad(true);
  setIsDataLoad(true);
  setSort((prevSort) => ({
    order: prevSort.orderBy === accessor && prevSort.order === 'desc' ? 'asc' : 'desc',
    orderBy: accessor,
  }));
  const newOrder = sort.orderBy === accessor && sort.order === 'desc' ? 'asc' : 'desc';
  tableRender(currentPage, monthPickerValue, accessor, newOrder, groupId);
};
  const count = filteredRows.length
  // const totalPages = Math.ceil(count / rowsPerPage)

  console.log(count, filteredRows, 'count');

  var location = useLocation();


 /**
  * The function `addCommasToNumber` takes a number as input and returns a string representation of the
  * number with commas added for thousands separators.
  * @param number - The `number` parameter is the number to which commas need to be added.
  * @returns The function `addCommasToNumber` returns a string with commas added to the number.
  */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  
    useEffect(()=>{
    console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
             <div className={"overflow-x-auto scrollbar rounded-b-[6px] "+(rows.length > 5 ? '':'pr-2')}>
              <table className="listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
                <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (

                        <th className={ i==0 ? "md:pl-5 md:pr-5 pl-5 pr-[9rem] ":"px-5 " +(" py-2 font-sans_book_body")} key={i}>
                          <span className="flex whitespace-nowrap "> 
                            <OverflowToolTip className="hover:text-[#000000] cursor-auto" component={<a className="hover:text-[#000000] cursor-auto">{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                      {rows.length > 0 ?
                        rows?.map((row, i) => {
                          return (
                            <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td className="w-[15%] 2xl:w-[10%] leading-[2rem] font-medium px-5 py-3 font-sans_book_body card-animate " style={{whiteSpace:"break-spaces"}} >
                                <div className='flex items-center'>
                                  {row?.icon_path == "default.png" || row?.icon_path == "/default.png" || !row?.icon_path ?
                                    <RandomLogo name={row?.acquirer_name} />
                                    :
                                    <CompanyLogo id={row?.acquirer_id} name={row.acquirer_name} page="company page" logo={row?.icon_path} />
                                  }
                                  <OverflowToolTip className=" w-[10rem] lg:max-w-[18rem]" component={
                                    <NavLink className=' flex text-start lg:inline	 leading-[1.1rem] lg:whitespace-nowrap hover:text-[#BC8B20]' state={{ prevPath: location.pathname }} to={routeNames.maAcquiresById(row.acquirer_id)}
                                    >  {row?.acquirer_name}
                                    </NavLink>} /> 
                                </div>
                              </td>

                              <td className="w-[5%] py-3 font-sans_book_body text-right px-5 pr-[2.8rem] lg:pr-[2.7rem] 2xl:pr-[3.7rem] ">
                                {row.acquisitions ? addCommasToNumber(row.acquisitions) : "--"}
                              </td>
                              <td className=" w-[15%] py-3 px-5 font-medium font-sans_book_body whitespace-nowrap" style={{whiteSpace:"break-spaces"}} >
                                {/* <OverflowToolTip component={
                                  row.top_acquistion_names ? row.top_acquistion_names : "--"} /> */}
                                <OverflowToolTip className="w-[10rem]" component={

                                  row?.top_acquistion?.length > 0 ?
                                    row?.top_acquistion?.map((company, i) => {
                                      return (
                                        <>
                                          {row?.top_acquistion?.length - 1 == i ?
                                            <CustomNavigation module="company" mainModule="ma" name={company.target_company} entityId={company?.company_id} />
                                            :
                                            <CustomNavigation module="company" mainModule="ma" name={company.target_company + ', '} entityId={company?.company_id} />
                                          }
                                        </>
                                      )
                                    })
                                    : "--"

                                } />
                              </td>
                              <td className="w-[15%] py-3 px-5 font-medium font-sans_book_body whitespace-nowrap" style={{whiteSpace:"break-spaces"}} >
                                <OverflowToolTip className=" w-[10rem] lg:max-w-[18rem]" component={
                                  <Link rel="noopener noreferrer" className=' flex text-start lg:inline	 lg:whitespace-nowrap hover:text-[#BC8B20]'
                                    
                                    to={routeNames.maCompanyById(row?.last_acquirer)}>
                                    {row.last_acquired ? row.last_acquired : "--"}
                                  </Link>
                                }
                                />
                              </td>
                              <td className=" w-[10%] py-3 font-sans_book_body text-right pr-[2.8rem]  xl:pr-[3rem] 2xl:pr-[3.5rem]">
                                <p>{row.total_acquistions != 0.00 ? addCommasToNumber(row.total_acquistions) : "--"}</p>
                              </td>
                              <td className={groupId?.myList?.length >0 ?"w-[5%] px-5 py-3 font-sans_book_body pl-1":"w-[5%] px-5 py-3 font-sans_book_body " }>
                              {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.acquirer_id} setReload={setReload}/>
                                 :<AddNotePopover className="" key={row.acquirer_id} companyId={row.acquirer_id} companyName={row.acquirer_name} type_id={Constants.moduleIds.acquirer_id} sub_type_master_id={sub_type_master_id}/>

                        }</td>
                            </tr>
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>

              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                  <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                    <NoDataPlaceholder />
                  </div>
                  </div>
                :
                <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                  <Loader />
                </div>
              }
            </div>
          </div>
        </div>
      </div>

      {data?.num_acquirers && rows.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_acquirers}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}
    </>
  )
}

export default Table
