
import { useState, useMemo, useEffect, useCallback, useContext } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom';
import { sortRows, filterRows, paginateRows } from '../helpers'
import Pagination from '../Pagination'
import CompanyLogo from '../CompanyLogo';
import SortIcon from 'mdi-react/SortIcon'
import SortAscendingIcon from 'mdi-react/SortAscendingIcon'
import SortDescendingIcon from 'mdi-react/SortDescendingIcon'
import '../TableView.css'
import { routeNames } from '../../routeSegments';
import { getPEGlobalSearchCompaniesApi } from '../../Config/config'
import RandomLogo from '../RandomLogo';
import OverflowToolTip from '../shared/OverflowToolTip';
import { rowsPerPageData, getMaCompaniesApi, dateRangeFilter } from '../../Config/config'
import { TableDataContext } from '../../Contexts/TableDataContext'
import axios from "axios";
import CustomNavigation from '../../Utils/CustomNavigation';
import NoDataPlaceholder from '../shared/NoDataPlaceholder';
import Loader from '../Loader';
import { UserContext } from '../../Contexts/UserContext';
import AddNotePopover from '../shared/AddNotePopover';
import Constants from '../../Constants';
import { PeFilterContext } from '../../Contexts/PeFilterContext';
import moment from "moment";
import { useRef } from 'react';
import DeleteItemsFromGroup from '../shared/DeleteItemsFromGroup';
import { toast } from 'react-toastify';


const Table = ({ columns, order, orderBy,sub_type_master_id}) => {

  const { rows, setRows, data, setData, isLoad, setIsLoad, setIsDataLoad, setTotalNoOfCompanies, setTableCompanyId, currentPage, setCurrentPage,setCurrentFilter, setAllDealIds, globalSearchResult } = useContext(TableDataContext);
  const { refineSearchFilters, monthPickerValue,groupId,resetDealsByFilter} = useContext(PeFilterContext);
  const [filters, setFilters] = useState({})
  const [activePage, setActivePage] = useState(currentPage)

  const [sort, setSort] = useState({ order: order, orderBy: orderBy })
  const [showId, setShowId] = useState(null);
  const [showNote, setShowNote] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  var location = useLocation();
  const abortRef = useRef(null)
  const[reload, setReload] = useState(false)

  /**
   * The function `findActivePage` sets the current page, renders a table, and sets a flag to indicate
   * that the page is loading.
   * @param page - The `page` parameter is used to specify the current page number. It is passed to the
   * `findActivePage` function to update the current page and perform some actions based on the updated
   * page value.
   */
  const findActivePage = (page) => {
    setCurrentPage(page)
    tableRender(page, monthPickerValue, sort.orderBy, sort.order,groupId)
    setIsLoad(true)
    // if(menu == "pe" || menu == "vc"){
    //   setActiveTabDeal('investments')
    // }
  }
  useEffect(()=>{
    console.log("jjjhhk inv" , location?.key)
    resetDealsByFilter();
  },[location?.key])
  console.log('refineSearchFiltersma', refineSearchFilters[location.pathname]);
/**
 * The function `isFromGlobalSearch` checks if there are any global search filters applied based on the
 * current location pathname.
 */
  const isFromGlobalSearch = ()=> refineSearchFilters[location.pathname]?.globalSearch?.length > 0 ? true : false

 /* The above code is a JavaScript function called `tableRender`. It is using the `useCallback` hook to
 memoize the function and optimize performance. */
  const tableRender = useCallback((page, month, column, order,groupId) => {
    console.log(month, 'ma usecallback month');
    // setIsTagClosed(0)
    // setIsMonthPickerChanged(0)
    axios(`${getMaCompaniesApi}`, {
      method: "POST",
      signal: abortRef.current.signal,
      data:{
        date_range: isFromGlobalSearch()
            ? {
                from_date: globalSearchResult?.date_range?.from_date,
                to_date: globalSearchResult?.date_range?.to_date,
              }
            : {
                from_date: moment(month[0]).format("YYYY-MM-DD"),
                to_date: moment(month[1]).format("YYYY-MM-DD"),
              },
        "order_by": {
          "column": column,
          "direction": order
        },
        from : isFromGlobalSearch() ? 'gs' : '',
        "page": page,
        company_ids: isFromGlobalSearch() ? globalSearchResult?.company?.company_ids.length === 0
            ? [0] : globalSearchResult?.company?.company_ids
            : refineSearchFilters[location.pathname]?.globalSearch?.at(0)?.value,
          acquirer_group: refineSearchFilters[location.pathname]?.acquirer_group?.map(opt => opt.name),
        "acquirer_city": refineSearchFilters[location.pathname]?.acquirerCity?.map(city => city.id),
        "acquirer_ids":refineSearchFilters[location.pathname]?.acquirers?.map(acq => acq.id) ,
        "acquirer_company_type": refineSearchFilters[location.pathname]?.acquirerType?.map(type => type.id),
        "acquirer_country": refineSearchFilters[location.pathname]?.acquirerCountries?.map(city => city.id),
        "company_group": refineSearchFilters[location.pathname]?.group?.map(group => group.name),
        "acquirer_region": refineSearchFilters[location.pathname]?.acquirerRegion?.map(city => city.id),
        "acquirer_state": refineSearchFilters[location.pathname]?.acquirerState?.map(city => city.id),
        "deal_range": refineSearchFilters[location.pathname]?.dealRange?.at(0) && {
          "from": refineSearchFilters[location.pathname]?.dealRange[0].name,
          "to": refineSearchFilters[location.pathname]?.dealRange[1].name
        },
        "deal_type": refineSearchFilters[location.pathname]?.dealType?.map(type => type.id),
        "ladvisor_ids":refineSearchFilters[location.pathname]?.ladvisors?.map(acq => acq.id)  ,
        // "operator" : refineSearchFilters[location.pathname]?.operator,
        "tadvisor_ids":refineSearchFilters[location.pathname]?.tadvisors?.map(acq => acq.id)  ,
        "target_city": refineSearchFilters[location.pathname]?.city?.map(city => city.id),
        "target_company_type": refineSearchFilters[location.pathname]?.companyType?.map(type => type.id),
        "company_ids":refineSearchFilters[location.pathname]?.target_company?.map(company => company.id),
        "target_country": refineSearchFilters[location.pathname]?.countries?.map(country => country.id),
        "target_industry": refineSearchFilters[location.pathname]?.industry?.map(value => value.id),
        "target_sector" : refineSearchFilters[location.pathname]?.sector?.map(value => value.name),
        "target_region": refineSearchFilters[location.pathname]?.region?.map(region => region.id),
        "target_state": refineSearchFilters[location.pathname]?.state?.map(state => state.id),
        "valuation": refineSearchFilters[location.pathname]?.valuations?.map(valuation => valuation.value),
        group:{  
          group_id: groupId?.myList?.map(id => id.group_id)?.toString() ?? null,
          sub_type_master_id: groupId?.myList?.length >0 ? sub_type_master_id :null   
        }
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },

    })
      .then((response) => {
        if (response?.data?.message == "Success") {
          setCurrentFilter(response.config.data)
          // console.log(response,'cfs table response');       
          setTotalNoOfCompanies(response?.data?.num_companies)
          setIsLoad(false)
          setIsDataLoad(false)
          // setIsMonthPickerChanged(0)
          setRows([])
          setRows(response?.data?.companies)
          setData(response?.data)
          setTableCompanyId([])
          setTableCompanyId(response?.data?.all_companies_ids)
          setAllDealIds(response?.data?.all_deal_ids)
          console.log(response?.data, 'cfs table response');
        }
      })
      .catch((error) => {
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
          toast.error(`Session Expired!`);
          setTimeout(() => logout(), 1000)
        }
        console.log("API not working", error);
      });
  }, [refineSearchFilters]);


  useEffect(() => {
    const abortController = new AbortController()
    abortRef.current = abortController;
    setIsLoad(true)
    setIsDataLoad(true)
    setCurrentPage(currentPage);
    tableRender(currentPage, monthPickerValue, sort.orderBy, sort.order,groupId )
    return () => {
      abortController.abort();
    }
  }, [tableRender, monthPickerValue,groupId,reload]);

 /**
  * The addNote function toggles the visibility of a note and sets the id of the note to be shown.
  * @param id - The `id` parameter is the identifier of a note.
  */
  const addNote = (id) => {
    setShowNote(!showNote)
    setShowId(id)
  }

  const rowsPerPage = rowsPerPageData

/* The above code is using the `useMemo` hook in React to memoize the result of calling the
`filterRows` function with the `rows` and `filters` variables as arguments. This means that the
`filterRows` function will only be called again if either `rows` or `filters` change. The memoized
result is stored in the `filteredRows` constant. */
  const filteredRows = useMemo(() => filterRows(rows, filters), [rows, filters])
  // const sortedRows = useMemo(() => sortRows(filteredRows, sort), [filteredRows, sort])
  // const calculatedRows = paginateRows(sortedRows, currentPage, rowsPerPage)
  // console.log(calculatedRows, "cfs calculated")

 /**
  * The function `handleSort` is used to handle sorting of data in a table based on a given accessor
  * and order.
  * @param accessor - The `accessor` parameter is a value that represents the property or key of the
  * object that you want to sort by. It is used to identify the specific property or key that you want
  * to sort the data by.
  * @param order - The "order" parameter is used to specify the sorting order. It can have two possible
  * values: "asc" for ascending order and "desc" for descending order.
  */
const handleSort = (accessor) => {
  setCurrentPage(currentPage); // Assuming currentPage is defined elsewhere
  setRows([]);
  setIsLoad(true);
  setIsDataLoad(true);
  setSort((prevSort) => ({
    order: prevSort.orderBy === accessor && prevSort.order === 'desc' ? 'asc' : 'desc',
    orderBy: accessor,
  }));
  const newOrder = sort.orderBy === accessor && sort.order === 'desc' ? 'asc' : 'desc';
  tableRender(currentPage, monthPickerValue, accessor, newOrder, groupId);
};
  const count = filteredRows?.length
  // const totalPages = Math.ceil(count / rowsPerPage)

  /**
   * The function `addCommasToNumber` takes a number as input and returns a string representation of
   * the number with commas added for thousands separators.
   * @param number - The `number` parameter is the number to which commas need to be added.
   * @returns The function `addCommasToNumber` returns a string representation of the input number with
   * commas added as thousands separators.
   */
  const addCommasToNumber = (number) => {
    return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };
  useEffect(()=>{
    console.log(location?.state?.isFromGlobalSearch, 'isFromGlobalSearch');

  },[location?.state?.isFromGlobalSearch])

  return (
    <>
      <div className="md:col-span-4 col-span-5">
        <div className="lg:col-span-4">
          <div className="scrollbar ">
            <div className={"overflow-x-auto scrollbar rounded-b-[6px] "+(rows?.length > 5 ? '':'pr-2')}>
            <table className="listPage table w-full p-1 bg-grey-50 divide-y" width="50%">
              <thead className="bg-[#EBEBEB] text-[11pt] leading-[16pt] lg:text-[13px] lg:leading-[15px] font-semibold text-[#666666]  text-left">
                  <tr>
                    {columns.map((column, i) => {
                      const sortIcon = () => {
                        if (column.accessor === sort.orderBy) {
                          if (sort.order === 'asc') {
                            return <SortDescendingIcon className="pl-2 block h-[15px]" />
                          }
                          return <SortAscendingIcon className="pl-2 block h-[15px]" />
                        } else {
                          return <SortIcon className="pl-2 block h-[15px] " />
                        }
                      }
                      return (

                        <th className={ i==0 ? "md:pl-5 md:pr-5 pl-5 pr-[16rem] ":"px-5 " +(" py-2 font-sans_book_body")} key={i}>
                          <span className="flex whitespace-nowrap ">
                            <OverflowToolTip  component={<a className='cursor-text hover:text-[#000000]'>{column.accessor == "add" && groupId?.myList?.length > 0 ? "Delete": column.label ? column.label : "--"}</a>} />
                            {column.accessor == "add" ? "" :
                              <button onClick={() => handleSort(column.accessor, sort.order)}>{sortIcon()}</button>
                            }</span>
                        </th>
                      )
                    })}
                  </tr>
                </thead>
                {!isLoad ?
                  count > 0 ?
                    <tbody className="text-[11pt] leading-[16pt] lg:text-[14px] lg:leading-[16px] font-sans_book_body text-[#333333] text-left">
                      {rows?.length > 0 ?
                        rows?.map((row, i) => {
                          return (
                            <tr key={i} className=" group hover:border-l-4 hover:border-l-[#BC8B20] border-l-4 border-l-[#FFFFFF]   border-b bg-white text" >
                              <td className="w-[20%] 2xl:w-[15%] leading-[2rem] px-5 py-3 font-sans_book_body card-animate " style={{whiteSpace:"break-spaces"}} >
                                <div className='flex items-center'>
                                  {row?.logo_path == "default.png" || row?.icon_path == "/default.png" || !row?.icon_path ?
                                    <RandomLogo name={row?.company} />
                                    :
                                    <CompanyLogo id={row?.company_id} name={row.company} page="company page" logo={row?.icon_path} />
                                  }
                                   
                                  <OverflowToolTip className=" w-full lg:max-w-full" component={
                                    <NavLink className=' flex text-start leading-[1.1rem] lg:inline lg:whitespace-nowrap hover:text-[#BC8B20] font-medium' to={routeNames.maCompanyById(row.company_id)}
                                    >  {row?.company ? (
                                      <>
                                        {row?.company}
                                        {row?.agghide != "" && (
                                          <span className="rounded-md bg-[#f3e8ff] px-2  py-[3px] ml-2 text-xs  font-sans_book_body text-[#3939ab]">
                                            {row?.agghide}
                                          </span>
                                        )}
                                        {row?.asset != "" && (
                                          <span className="rounded-md bg-[#f3e8ff] px-2  py-[3px] ml-2 text-xs  font-sans_book_body text-[#3939ab]">
                                            {row?.asset}
                                          </span>
                                        )}
                                      </>
                                    ) : (
                                      "--"
                                    )}
                                    </NavLink>} 
                                    />
                                </div>
                              </td>

                              <td className="w-[12%] py-3 px-5 font-sans_book_body whitespace-nowrap"  style={{whiteSpace:"break-spaces"}} >
                               
                                <OverflowToolTip className="w-[10rem]"  component={<span className=''>{row.sector ? row.sector : "--"}</span>} />
                                 
                              </td>
                              <td className="w-[14%] py-3 px-5 font-sans_book_body whitespace-nowrap" style={{whiteSpace:"break-spaces"}} >
                                <OverflowToolTip className="w-[10rem]" component={<NavLink className='  flex text-start lg:inline leading-[1.1rem] hover:text-[#BC8B20] font-medium' state={{ fromPath: location.pathname }} to={routeNames.maAcquiresById(row.acquirer_id)}>{row.acquirer ? row.acquirer : "--"}</NavLink>} />
                              </td>
                              <td className="w-[6%] py-3 px-5 font-sans_book_body whitespace-nowrap" >
                                {row.deal_date ? <Link className='hover:text-[#BC8B20] font-medium'
                                  to={{
                                    pathname: routeNames.maCompanyInvestmentDealById(row?.company_id, row?.deal_id)
                                  }}>
                                  {row.deal_date}
                                </Link> : "--"}
                              </td>
                              <td className=" w-[7%]  pr-[3rem] lg:pr-[3rem] py-3  font-sans_book_body text-right"><p>{row.deal_amount != 0.00 ? addCommasToNumber(row.deal_amount) : "--"}</p></td>
                              <td className={groupId?.myList?.length >0 ?"w-[3%] px-5 py-3  font-sans_book_body  pl-2":"w-[3%] px-5 py-3  font-sans_book_body " }>
                              {groupId?.myList?.length >0 ? 
                                 <DeleteItemsFromGroup id={row.company_id} group_id={row?.grp_lt_item_id} type_id={Constants.moduleIds.companiesId} setReload={setReload}/>
                                 : <AddNotePopover className="" key={row.company_id} companyId={row.company_id} companyName={row.company} type_id={Constants.moduleIds.companiesId} sub_type_master_id={sub_type_master_id}/>

                        }</td>
                            </tr>
                          )
                        })
                        : <center><div className="h-[4rem] text-[12pt] lg:text-[15px] text-[15px] text-[#333333] relative mt-[3rem] left-[30rem]">No data found</div></center>
                      }
                    </tbody>
                    : <></>
                  : <></>
                }
              </table>

              {!isLoad ?
                count > 0 ?
                  <></>
                  : <div className='pr-2'>
                  <div className='w-full h-[17rem] bg-white flex items-center justify-center fixed md:relative'>
                    <NoDataPlaceholder />
                  </div>
                </div>
              :
              <div className='w-full h-[17rem] bg-white flex items-center justify-center absolute md:relative'>
                <Loader />
              </div>
              }
            </div>
          </div>
        </div>
      </div>


      {data?.num_companies && rows?.length > 0 ? (
        <Pagination
          className="pagination-bar"
          currentPage={currentPage}
          totalCount={data?.num_companies}
          pageSize={20}
          onPageChange={(page) => findActivePage(page)}
        />

      ) : (
        ""
      )}
    </>
  )
}

export default Table
