import React, { useContext } from "react";
import Layout from '../../Components/VC/VcCompanyLayout'
import MainMenu from '../../Components/VC/Mainmenu'
import Table from '../../Components/TableComponent'
// import {PE_VC_TABLE_QUERY} from '../../Queries/Pe';
import Card from '../../Components/ListPageCard/IncubationCard'
import RefineSearch from '../../Components/RefineSearchComponent'
import FilterTag from '../../Components/Tag'
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
import Constants from '../../Constants';
import IncubationRefineSearch from '../../Components/RefineSearch/IncubationRefineSearch';
import { useLocation } from 'react-router-dom';
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { Switch, Tooltip } from "antd";
import { UserContext } from "../../Contexts/UserContext";
import ToggleDealsViewButton from "../../Components/ToggleDealsView";
/**
 * The function `Vc` is a React component that renders a layout with a top navigation bar, a main area
 * with a primary column and a secondary column, and a bottom section.
 * @returns The function `Vc` returns JSX elements wrapped in a `Layout` component. The returned JSX
 * structure consists of a `div` with a class of "flex h-screen flex-col" as the root element. Inside
 * this `div`, there are two main sections: the top navigation section and the bottom section.
 */
function Vc() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();

    const { pathname } = useLocation(); 
 
    const { refineSearchFilters, dealsView, setDealsView, switchValue, setSwitchValue,isMobile } = useContext(PeFilterContext);
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const { isDirectoryUser } = useContext(UserContext);

    const dealsViewHandler = (checked) =>{
        setSwitchValue(checked);
        if(checked){ setDealsView(true) }else setDealsView(false);
    }
   
    return (
        <Layout>
        <div className="flex h-screen flex-col">
                    {/* Top nav*/}
                    {isFromGlobalSearch () ? "" :   <div className="hidden lg:block"><Card /> </div>}
                    {/* Bottom section */} 
                    <div className="flex min-h-0 flex-1">
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 flex":"min-w-0 flex-1 border-t border-gray-200 flex"}>
                            {/* Primary column */}
                            <section
                                className="flex h-full min-w-0 flex-1 overflow-y-auto flex-col lg:order-last">
                                {/* Your content */}
                                <div className="mr-[4.25rem] flex justify-between items-center">
                                <FilterTag />
                                <ToggleDealsViewButton />
                                </div>
                                {/* <div className='px-3'> */}
                                <MainMenu active={5} menu="incubation"  primaryTypeMasterId={Constants.primaryMasterIds.vcId}
                                subTypeMasterId={Constants.primaryMasterSubIds.vc_incubation_id}
                                typeId={Constants.moduleIds.companiesId}
                                exportFileName={Constants.exportNames.vc_company_incubation}/> 
                                <div className='overflow-y-auto'>
                                    <Table menu="vc-incubation" sub_type_master_id={Constants.subCategoryIds.vc_incubation_id}/>
                                </div>
                                {/* </div> */}
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className={`order-first block flex-shrink-0 ${isMobile ? "pt-14" : ""}`}>
                                <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch>
                                        <IncubationRefineSearch customPathname={location.pathname}/>
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>
                    
                </div>
        </Layout>
    )
}

export default Vc
