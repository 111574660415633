import React from 'react'
import { useLocation } from 'react-router-dom'
// import Card from '../../Components/Investor/InvestorPageCard/Card'
import Layout from '../../Components/InvestorLayout'
import Table from '../../Components/Investor/InvestorFunds/TableComponent'
import RefineSearch from '../../Components/RefineSearchComponent'
import MainMenu from '../../Components/Investor/InvestorFunds/VcFundsMainmenu'
import chatPopupIcon from '../../images/chatPopupIcon.png'
import ChatPopup from '../../Components/ChatPopup'
import TopSection from '../../Components/InvestorLimitedpartnerDetail/TopSection'
import FundsBreadCrum from './FundsBreadCrum'
import FilterTag from '../../Components/Tag'
import Constants from '../../Constants'
import InvestorFundRefineSearch from '../../Components/RefineSearch/InvestorFundRefineSearch'
import useModule from '../../hooks/useModule'

/**
 * The `InvestorFunds` function is a React component that renders a layout with a sidebar and a main
 * content area, displaying a table of investor funds and providing options for filtering and refining
 * the search.
 * @returns The `InvestorFunds` component is returning a JSX element.
 */
const InvestorFunds = () => {
    const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();
    return (
        <div>
            <>
                <Layout>
                    <div className="flex h-screen flex-col">

                        <TopSection />

                        <div className="flex min-h-0 flex-1 overflow-hidden">
                            {/* Narrow sidebar*/}
                            {/* Main area */}
                            <main className="min-w-0 flex-1 border-t border-gray-200 flex">
                                {/* Primary column */}
                                <section
                                    className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                                >
                                    {/* Your content */}
                                    <FilterTag />
                                    <FundsBreadCrum  menu = "investorvc" subMenu="vc" />
                                    <MainMenu menu="vcinvestor"  primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_funds_id}
                                        typeId={Constants.moduleIds.investorsId}
                                        exportFileName={Constants.exportNames.vc_funds}/>
                                    <div className="overflow-y-auto">
                                        <Table menu="inv_l_partner_vc" />
                                    </div>
                                </section>
                                {/* Secondary column (hidden on smaller screens) */}
                                <aside className="order-first block flex-shrink-0">
                                    <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                        

                                        <RefineSearch>
                                            <InvestorFundRefineSearch customPathname={location.pathname} />
                                        </RefineSearch>
                                    </div>
                                </aside>
                            </main>
                        </div>
                        <br />

                        {/* </div> */}
                    </div>
                </Layout>
            </>
        </div>
    )
}

export default InvestorFunds