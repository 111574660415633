import { React, useState, useEffect,useContext } from 'react'
import { useParams } from "react-router-dom";
import { alertListApi, createAlertListApi, updateAlertListApi } from '../../Config/config'
import axios from "axios";
import Warning from "../../images/warning-btn.png"
import alert from "../../images/alert.png"
import { toast} from "react-toastify";
import { Tooltip } from "antd";
import { UserContext } from '../../Contexts/UserContext';
import useModule from '../../hooks/useModule';
import Popup from 'reactjs-popup';
import Constants from '../../Constants';


/**The above code is a React component called `AlertPopUp`. It renders a button that triggers a popup
when clicked. The popup displays a list of checkboxes representing different alerts. The user can
select or deselect the checkboxes to choose the alerts they want. After selecting the alerts, the
user can click the "Set Alert" or "Update Alert" button to save or update the selected alerts
respectively. The component makes API calls to retrieve the list of alerts and to save/update the
selected alerts. */
const AlertPopUp = (props) => {
  const [alertList, setAlertList] = useState()
  const [createAlertList, setCreateAlertList] = useState()
  const [checked, setChecked] = useState(new Set())
  const [showText, setShowText] = useState(false);
  const [open, setOpen] = useState(false)
  const { getToken, logout } = useContext(UserContext);
  const { primaryMasterId } = useModule();
  const {dealId} = useParams()

  const onAddingAlert = (event) => {
    if (event.target.checked) {
      setChecked(prev => ([...prev, parseInt(event.target.id)]))
    } else {
      setChecked(prev => {
        let temp = new Set(prev)
        temp.delete(parseInt(event.target.id))
        return temp;
      })
    }
  };

  useEffect(() => {
    listAlert()
  }, [props.cid]);

  const isShare = () => {
    setShowText(true)
    setOpen(true)
  }

  const listAlert = () => {
    axios(`${alertListApi}`, {
      method: "POST",
      data: {
        // "user_id": userId,
        "type_id": props.type_id,
        "entity_id": props.cid,
        primary_type_master_id: primaryMasterId,
        "deal_id":props?.page != 'fundDetail' && props?.currentDealId ? props?.currentDealId :""      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        // console.log(response, 'alert list api response');
        if (response?.data?.success == true) {
          setAlertList(response?.data)
          let selectedAlerts = response?.data?.alerts?.filter(alert => alert.selected)?.map(sAlert => sAlert.id);
          setChecked(selectedAlerts)
        }
      })
      .catch((error) => {
        console.log("API not working", error);
        if (error?.response?.status == Constants.sessionTerminatedStatus) {
            toast.error(`Session Expired!`);
            setTimeout(() => logout(), 1000)
        }
      });

  }
  const saveAlert = () => {

    if (Array.from(checked).findIndex(value => value) === -1) {
      toast.warning("Please select alerts")
      return
    }
    // if(Array.from())
    // if(checked.length === 0 ){
    //   toast.warning("Please select alerts")
    //   return
    // }


    // console.log(checked, 'checked items');
    // console.log("alert_module_id :", checked, "entity_id :", props.cid, "type_id:", props.type_id);
    setShowText(!showText)
    setOpen(false);
    axios(`${createAlertListApi}`, {
      method: "POST",
      data: {
        // "user_id": userId,
        "alert_module_id": Array.from(checked),
        "entity_id": props.cid,
        "type_id": props.type_id,
        primary_type_master_id: primaryMasterId,
        "deal_id":props?.page != 'fundDetail' && props?.currentDealId ? props?.currentDealId :""      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        // console.log(response, 'create alert list api response');
        if (response?.data?.success == true) {
          setCreateAlertList(response?.data)
          // setMsg(response?.data?.message)
          // setShowNotification(true)
          toast.success(response?.data?.message)
          listAlert()
        }

        else {
          // setMsg(response?.data?.message)
          // setShowNotification(true)
          toast.warning(response?.data?.message)

        }
      })
      .catch((error) => {
        console.log("API not working", error);
      });

  }
  // console.log(Array.from(checked), 'updatechecking');
  const updateAlert = () => {
    console.log(Array.from(checked), 'updatechecking');
    // if(Array.from(checked).findIndex(value => value) === (Array.from(checked)) ){
    //   toast.warning("Please select alerts")
    //   return 
    // }

    // console.log("alert_module_id :", checked, "entity_id :", props.cid, "type_id:", props.type_id);
    setShowText(!showText)
    setOpen(false);
    axios(`${updateAlertListApi}`, {
      method: "POST",
      data: {
        // "user_id": userId,
        "alert_module_id": Array.from(checked),
        "entity_id": props.cid,
        "type_id": props.type_id,
        primary_type_master_id: primaryMasterId,
        "deal_id":props?.page != 'fundDetail' && props?.currentDealId ? props?.currentDealId :""

        
      },
      headers: {
        "Content-Type": "application/json",
        "Authorization": getToken()
      },
    })
      .then((response) => {
        // console.log(response, 'update alert list api response');
        if (response?.data?.success == true) {
          // setCreateAlertList(response?.data)
          // setMsg(response?.data?.message)
          toast.success(response?.data?.message)
          // setShowNotification(true)
          listAlert()
        }
        else {
          // setMsg(response?.data?.message)
          // setShowNotification(true)
          toast.warning(response?.data?.message)

        }
      })
      .catch((error) => {
        console.log("API not working", error);
        if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
            toast.error(`Session Expired!`);
            setTimeout(() => logout(), 1000)
        }
      });


  }
  const pages =
    props?.page == "detailviewLp"
      ? 'top-[10rem]'
      : props?.page == 'fundDetail'
        ? 'top-[10rem]'
        : props?.page == 'AngelDetail'
          ? 'top-[9.5rem]'
          : "";


  return (
    <div >
      <Popup className='react-popup'
        contentStyle={{ padding: 0, marginTop: '8px', border: 'none', width: props?.page == 'advisor' || props?.page == 'detailpage' || props?.page == 'investor' ? '' : 'fit-content' }} on={['click']} trigger={<div>
          <Tooltip title="Alert">
            <button
              type="button"
              onClick={isShare}
              className="bg-white rounded-full flex text-sm focus:outline-none "
            >
              <img
                className={showText ? "w-[24px] h-[22px] cursor-pointer" : "w-[24px] h-[22px] cursor-pointer"}
                src={open ? alert : Warning}
                alt=""
              />
            </button>
          </Tooltip>
        </div>} position="bottom center"
        onOpen={() => setOpen(true)}
        onClose={() => { setOpen(false); setShowText(false) }}
      >
        {close => (
          showText &&
          <div className='w-fit rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className="p-5 space-y-2 font-sans_book_body" onClose={() => setOpen(false)}>
              {alertList?.alerts?.length > 0 ?
                alertList?.alerts?.map((alert, i) => {
                  return (
                    <div className="relative flex items-start" key={alert.id}>
                      <div className="flex h-5 items-center">
                        <input
                          id={alert.id}
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          className="h-4 w-4 rounded border-gray-300 text-[#BC8B20]"
                          value={alert.alert_name}
                          defaultChecked={alert.selected}
                          onChange={onAddingAlert}
                        />
                      </div>
                      <div className="ml-3 text-sm text-left">
                        <label htmlFor={alert.id} className="font-sans_book_body break-words">
                          {alert.alert_name}
                          {console.log(alert.alert_name, "zzz")}
                        </label>
                      </div>
                    </div>
                  )

                })
                : <div className="w-full bg-[#FFFFFF]">
                  <center>
                    <div role="status" className=" h-[8rem]">
                      <svg className="relative top-[1rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                      </svg>
                    </div>
                  </center>
                </div>
              }
            </div>
            <div className="flex items-center justify-center pb-5 px-5">
              <button
                type="button"
                className=" bg-[#BC8B20]  focus:outline-none hover:bg-[#a37517] w-[122px] h-[31px] text-[#FFFFFF] text-[15px] rounded-[3px]  font-sans_roman_body leading-[17px] "
                onClick={() => {
                  close()
                  if (alertList?.method === "update") {
                    updateAlert()
                  }
                  else {
                    saveAlert()
                  }
                }}>
                {alertList?.method === "update" ? "Update Alert" : "Set Alert"}
              </button>
            </div>
          </div>
        )}
      </Popup>
    </div>
  );
};
export default AlertPopUp;
