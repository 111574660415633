import React, { useState, useEffect, useContext, useRef } from "react";
import "../../src/index.css";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import SearchColumn from "./SearchPopup/SearchColumn";
import { routeNames } from "../routeSegments";
import { UserContext } from "../Contexts/UserContext";
// import { PeFilterContext } from "../Contexts/PeFilterContext";

import { getSearch } from "../Config/config";
import Loader from "./Loader";
// import { range } from "lodash";
// import Constants from "../Constants";
import useModule from "../hooks/useModule";
import { TableDataContext } from "../Contexts/TableDataContext";
import { toast } from "react-toastify";
import Constants from "../Constants";
  

const moveArray = function (array, from, to) {
  const internal = [...array]
  internal.splice(to, 0, internal.splice(from, 1)[0]);
  return internal;
};

const getInvestorUrlByCatagory = (id, catagory) => {
  if (catagory === "EXIT") {
    return routeNames.peExitInvestorById(id);
  } else if (catagory === "IPO") {
    return routeNames.peIpoInvestorById(id);
  } else if (catagory === "ANGEL") {
    return routeNames.peAngelInvestorById(id);
  } else if (catagory === "INCUBATOR") {
    return routeNames.peIncubationInvestorById(id);
  } else {
    return routeNames.peInvestorById(id);
  }
}

const Modal = ({ show, onClose, type, searchFrom, searchInput,setSearchInput,searchLabel,setSearchText, setPopOver }) => {

  const {isPE} = useModule()


  const columns = {

    pe_primary: [
      {
        columnName: "Company",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Companies",
        columnKey: "company",
        columnLabelLink: (path) => path === 'exits'?  routeNames.pe_companies_exists_pe_vc_investments: path === 'ipo' ? routeNames.pe_companies_backed_ipos_pe_vc_investments : path === 'angel' ? routeNames.pe_companies_angel_investments : path === 'incubation' ?  routeNames.pe_companies_incubation : routeNames.pe_companies_pe_vc_investments,
        columnLink: (id) => routeNames.peCompanyFundById(id),
        allIdsKey: "company_ids",
        getFilterLabel: (text) => `Searched companies - ${text}`,
      },
      {
        columnName: "Investor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Investors",
        columnKey: "investor",
        columnLabelLink: (path) => path === 'exits'?  routeNames.pe_investors_exists_pe_vc_investments: path === 'ipo' ? routeNames.pe_investors_backed_ipos_pe_vc_investments : path === 'angel' ? routeNames.pe_investors_angel_investments : path === 'incubation' ?  routeNames.pe_investors_incubation : routeNames.pe_investors_pe_vc_investments,
        columnLink: getInvestorUrlByCatagory,
        allIdsKey: "investor_ids",
        getFilterLabel: (text) => `Searched investors - ${text}`,
      },
      {
        columnName: "Legal Advisors",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" /></svg>,
        columnLabel: "L-Advisors",
        columnKey: "l_advisor",
        columnLabelLink: (path) => path === 'exits' ? routeNames.pe_advisors_ma_advisors_exits : routeNames.pe_advisors_pe_vc_advisors ,
        columnLink: (id) => routeNames.peAdvisorById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      },
      {
        columnName: "Transaction Advisor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /></svg>,
        columnLabel: "T-Advisors",
        columnKey: "t_advisor",
        columnLabelLink: (path) => path === 'exits'?  routeNames.pe_transaction_advisors_ma_advisors_exits :routeNames.pe_transaction_advisors_pe_vc_advisors,
        columnLink: (id) => routeNames.peTransactionAdvisorById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      }
    ],
    vc_primary: [
      {
        columnName: "Company",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Companies",
        columnKey: "company",
        columnLabelLink: (path) => path === 'exits'?  routeNames.vc_companies_exists_vc_investments: path === 'ipo' ? routeNames.vc_companies_backed_ipos_vc_investments : path === 'angel' ? routeNames.vc_companies_angel_investments : path === 'incubation' ?  routeNames.vc_companies_incubation : routeNames.vc_companies_vc_investments,
        columnLink: (id) => routeNames.vcCompanyFundById(id),
        allIdsKey: "company_ids",
        getFilterLabel: (text) => `Searched companies - ${text}`,
      },
      {
        columnName: "Investor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Investors",
        columnKey: "investor",
        columnLabelLink: (path) => path === 'exits'?  routeNames.vc_investors_exists_vc_investments: path === 'ipo' ? routeNames.vc_investors_backed_ipos_vc_investments : path === 'angel' ? routeNames.vc_investors_angel_investments : path === 'incubation' ?  routeNames.vc_investors_incubation : routeNames.vc_investors_vc_investments,
        columnLink: (id) => routeNames.vcInvestorById(id),
        allIdsKey: "investor_ids",
        getFilterLabel: (text) => `Searched investors - ${text}`,
      },
      {
        columnName: "Legal Advisors",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" /></svg>,
        columnLabel: "L-Advisors",
        columnKey: "l_advisor",
        columnLabelLink: (path) => path === 'exits' ? routeNames.vc_advisors_ma_vc_advisors_exits : routeNames.vc_advisors_vc_advisors ,
        columnLink: (id) => routeNames.vcAdvisorById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      },
      {
        columnName: "Transaction Advisor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /></svg>,
        columnLabel: "T-Advisors",
        columnKey: "t_advisor",
        columnLabelLink: (path) => path === 'exits'?  routeNames.vc_transaction_advisors_ma_vc_exits :routeNames.vc_transaction_advisors_vc_advisors,
        columnLink: (id) => routeNames.vcTransactionAdvisorById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      }
    ],
    re: [
      {
        columnName: "Company",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Companies",
        columnKey: "company",
        columnLabelLink: (path) => path === 'exits'?  routeNames.re_companies_re_exits : path === 'ipo' ? routeNames.re_companies_re_ipo : path === 'otherMa' ? routeNames.re_companies_other_ma : routeNames.re_companies_re_investments,
        columnLink: (id) => routeNames.reCompanyById(id),
        allIdsKey: "company_ids",
        getFilterLabel: (text) => `Searched companies - ${text}`,
      },
      {
        columnName: "Investor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Investors",
        columnKey: "investor",
        columnLabelLink: (path) => path === 'exits'?  routeNames.re_investors_exists : path === 'ipo' ? routeNames.re_investors_backed_ipos : path === 'otherMa' ? routeNames.re_investors_other_ma : routeNames.re_investors_re_investments,
        columnLink: (id) => routeNames.reInvestorById(id),
        allIdsKey: "investor_ids",
        getFilterLabel: (text) => `Searched investors - ${text}`,
      },
      {
        columnName: "Legal Advisors",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" /></svg>,
        columnLabel: "L-Advisors",
        columnKey: "l_advisor",
        columnLabelLink: (path) => path === "exits" ? routeNames.re_advisors_re_exits : path === "otherMa" ? routeNames.re_advisors_other_ma :routeNames.re_advisors_re_investments,
        columnLink: (id) => routeNames.reAdvisorById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      },
      {
        columnName: "Transaction Advisor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /></svg>,
        columnLabel: "T-Advisors",
        columnKey: "t_advisor",
        columnLabelLink: (path) => path === "exits" ? routeNames.re_transaction_advisors_re_exits : path === "otherMa" ? routeNames.re_transaction_advisors_other_ma :routeNames.re_transaction_advisors_re_investments,
        columnLink: (id) => routeNames.reTransactionAdvisorById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      }
    ],
    funds: [
      {
        columnName: "Investor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Investors",
        columnKey: "investor",
        columnLink: (id) => routeNames.investorById(id),
        allIdsKey: "fund_ids",
        getFilterLabel: (text) => `Searched investors - ${text}`,
        columnLabelLink: () => routeNames.pe_investors_pe_vc_investments,
      },
      {
        columnName: "Funds",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Funds",
        columnKey: isPE ? "fund" : "re_fund",
        columnLink: (id,dealId) => isPE ? routeNames.pe_investors_funds_detail_page(id,dealId) : routeNames.re_investors_funds_detail(id,dealId),
        allIdsKey: "fund_ids",
        getFilterLabel: (text) => `Searched funds - ${text}`,
        columnLabelLink: () => isPE ? routeNames.pe_investors_pe_vc_funds : routeNames.re_investors_funds ,
      },
    ],
    limited_partners: [
      {
        columnName: "Investor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Investors",
        columnKey: "investor",
        columnLink: (id) => routeNames.investorById(id),
        allIdsKey: "lp_ids",
        getFilterLabel: (text) => `Searched investors - ${text}`,
        columnLabelLink: () => routeNames.pe_investors_pe_vc_investments,
      },
      {
        columnName: "Limited Partners",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Limited Partners",
        columnKey: "limited_partner",
        columnLink: (id) => routeNames.investorsLimitedPartnerById(id),
        allIdsKey: "lp_ids",
        getFilterLabel: (text) => `Searched limited partners - ${text}`,
        columnLabelLink: (path) => path === "exits" ? routeNames.pe_investors_exists_pe_vc_limited_partners :routeNames.pe_investors_pe_vc_limited_partners,

      },
    ],
    cfs: [
      {
        columnName: "Company",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Companies",
        columnLabelLink: () => routeNames.cfs,
        columnKey: "cfs",
        columnLink: (id) => routeNames.cfsCompanyPageById(id),
        allIdsKey: "company_ids",
        getFilterLabel: (text) => `Searched companies - ${text}`,
      },
    ],
    ma: [
      {
        columnName: "Company",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"><path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Companies",
        columnKey: "company",
        columnLabelLink: () => routeNames.ma_companies,
        columnLink: (id) => routeNames.maCompanyById(id),
        allIdsKey: "company_ids",
        getFilterLabel: (text) => `Searched companies - ${text}`,
      },
      {
        columnName: "Acquirer",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" /></svg>,
        columnLabel: "Acquirers",
        columnKey: "acquirer",
        columnLabelLink: () => routeNames.ma_acquires,
        columnLink: (id) => routeNames.maAcquiresById(id),
        allIdsKey: "acquirer_ids",
        getFilterLabel: (text) => `Searched Acquirers - ${text}`,
      },
      {
        columnName: "Legal Advisors",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z" /></svg>,
        columnLabel: "L-Advisors",
        columnKey: "l_advisor",
        columnLabelLink: () => routeNames.ma_advisors,
        columnLink: (id) => routeNames.maAdvisorsById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      },
      {
        columnName: "Transaction Advisor",
        columnLeading: <svg xclassName="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true"> <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z" /></svg>,
        columnLabel: "T-Advisors",
        columnKey: "t_advisor",
        columnLabelLink: () => routeNames.ma_transaction_advisor,
        columnLink: (id) => routeNames.maTransactionAdvisorsById(id),
        allIdsKey: "advisor_ids",
        getFilterLabel: (text) => `Searched advisors - ${text}`,
      }
    ]
  }
  const [searchResult, setSearchResult] = useState();
  const {globalSearchResult, setGlobalSearchResult} = useContext(TableDataContext);
  const [headerColumns, setHeaderColumns] = useState(columns.pe_primary)
  const location = useLocation();
  const navigate = useNavigate();
  const { getToken, logout } = useContext(UserContext);
  const [FetchRemaining, setFetchRemaining] = useState({show:false,total:0,current:0})
  // const { setrefineSearchFilters, setMonthPickerValue } = useContext(PeFilterContext);
  // console.log(setFetchRemaining,'setFetchRemaining');

  useEffect(() => {
    if (type === "pe_primary") {
      if (searchFrom === "company") {
        // console.log("searchFromsearchFrom", searchFrom, type, columns.pe_primary);
        setHeaderColumns(columns.pe_primary);
      } else if (searchFrom === "investor") {
        const orderedColumn = moveArray(columns.pe_primary, 1, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "l_advisor") {
        const orderedColumn = moveArray(columns.pe_primary, 2, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "t_advisor") {
        const orderedColumn = moveArray(columns.pe_primary, 3, 0)
        setHeaderColumns(orderedColumn);
      } 
      else {
        setHeaderColumns(columns[type]);
      }
    } else if (type === "vc_primary") {
      if (searchFrom === "company") {
        // console.log("searchFromsearchFrom", searchFrom, type, columns.vc_primary);
        setHeaderColumns(columns.vc_primary);
      } else if (searchFrom === "investor") {
        const orderedColumn = moveArray(columns.vc_primary, 1, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "l_advisor") {
        const orderedColumn = moveArray(columns.vc_primary, 2, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "t_advisor") {
        const orderedColumn = moveArray(columns.vc_primary, 3, 0)
        setHeaderColumns(orderedColumn);
      } else {
        setHeaderColumns(columns[type]);
      }
    } else if (type === "re") {
      if (searchFrom === "company") {
        // console.log("searchFromsearchFrom", searchFrom, type, columns.re);
        setHeaderColumns(columns.re);
      } else if (searchFrom === "investor") {
        const orderedColumn = moveArray(columns.re, 1, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "l_advisor") {
        const orderedColumn = moveArray(columns.re, 2, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "t_advisor") {
        const orderedColumn = moveArray(columns.re, 3, 0)
        setHeaderColumns(orderedColumn);
      } else {
        setHeaderColumns(columns[type]);
      }
    } else if (type === "limited_partners") {
      setHeaderColumns(columns.limited_partners)
    } else if (type === "funds" || type === "re_funds") {
      setHeaderColumns(columns.funds)
    } else if (type === "cfs") {
      setHeaderColumns(columns.cfs)
    } else if (type === "ma") {
      if (searchFrom === "company") {
        // console.log("searchFromsearchFrom", searchFrom, type, columns.pe_primary);
        setHeaderColumns(columns.ma);
      } else if (searchFrom === "acquirer") {
        const orderedColumn = moveArray(columns.ma, 1, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "l_advisor") {
        const orderedColumn = moveArray(columns.ma, 2, 0)
        setHeaderColumns(orderedColumn);
      } else if (searchFrom === "t_advisor") {
        const orderedColumn = moveArray(columns.ma, 3, 0)
        setHeaderColumns(orderedColumn);
      } 
      else {
        setHeaderColumns(columns[type]);
      }
      // setHeaderColumns(columns.ma)
    } else {
      setHeaderColumns(columns.pe_primary)
    }

  }, [searchFrom, type])

  // const getRemainingIds = async (pageCount, column) => {

  //   const generated = range(2, pageCount+1)
    

  //   const allIds = await Promise.all(generated.map(page => axios.post(getSearch, {
  //     "searchTerm": searchInput,
  //     "type": type,
  //     'page': page
  //   }, {
  //     headers: {
  //       "Authorization": getToken()
  //     }
  //   }).then(response => {
  //     if (response?.data?.message === 'Success') {
  //       const data = response.data[column.columnKey]?.[column.allIdsKey];
  //       setFetchRemaining(prev => ({ ...prev, current: prev.current + data.length }))
  //       return data
  //     } else {
  //       return []
  //     }
  //   }).catch(error => {
  //     console.log("error", error)
  //     return [];
  //   }))).catch(error => {
  //     console.log("error", error)
  //     return [];
  //   });

  //   console.log("generated",generated,allIds);

  //   return allIds ?? [];
  // }


  useEffect(() => {
    const sub = setTimeout(() => {
      if (searchInput?.length !== 0 && searchInput?.length >2) {
        axios.post(getSearch, {
          "searchTerm": searchInput,
          "type": type,
          'page': 1
        }, {
          headers: {
            "Authorization": getToken()
          }
        })
          .then(response => {
            if (response?.data?.message === 'Success') {
              setSearchResult(response.data);
              setGlobalSearchResult(response?.data);
            } else {
              setSearchResult(null);
            }
          })
          .catch((error) => {
            setSearchResult(0);
            if (error?.response?.status == Constants.sessionTerminatedStatus 
            || error?.response?.status == Constants.InvalidTokenStatus
          ){
            toast.error("Session Expired");
            setTimeout(() => logout(), 1000)
          }
            console.log("API not working", error);
          });
      } else {
        setSearchResult(null);
      }
    }, 400)

    return () => clearTimeout(sub)
  }, [searchInput, type])

  // const handleChange = (e) => {
  //   setSearchInput(e.target.value);
  // }

  const onOptionClick = () => {
    setSearchResult(null);
    setSearchInput('')
    onClose();
  }

  useEffect(() => {
    onOptionClick();
  }, [location.pathname])

  // console.log("seqr-s", searchResult);
/**
 * The function `onLableLinkClick` is used to handle the click event on a label link, which closes the
 * global search and navigates to a specified URL with the label state.
 */
  const onLableLinkClick = async (url, labelState, column, count) => {
   
    // const pageCount = Math.ceil(count / Constants.globalSearchMaxCount)
// console.log(url,'redirectional url');
    // if (pageCount > 1) {
    //   setFetchRemaining({show:true,current:0,total:count})
    //   const ids = await getRemainingIds(pageCount, column)
    //   setFetchRemaining({show:false,current:0,total:0})
    //   const allSearchIds = [...labelState?.globalSearch[0]?.value, ...flatten(ids)]
    //   const state = [{ id: 0, name: column.getFilterLabel(searchInput), value: allSearchIds }]
    //   closeGlobalSearch();
    //   navigate(url, { state: { ...labelState, globalSearch: state , isFromGlobalSearch: true} });
    // } else {
      closeGlobalSearch();
      navigate(url, { state: labelState });
    // }
  }

  const closeGlobalSearch = ()=>{
    setSearchResult(null);
    onClose();
  }

  const searchRef = useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchRef.current && !searchRef.current.contains(event.target) && event.target?.id !== "globalSearchInput") {
        setSearchInput("")
        onClose()
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [onClose, searchInput?.length, searchRef, setSearchInput]);

  const handleMobileChange = (e) => {    
    setSearchInput(e.target.value);
    setSearchText(e.target.value)
    // if(e.target.value.length > 2) {
    //   setPopOver(true)
    //   }
    //  else if(e.target.value.length==0) {
    //     setPopOver(false)
    //   }
    //   else{
    //     setPopOver(false)
    //   }
  }
  const handleMobilePaste =(e)=>{   
    const pastedValue= e.clipboardData.getData('Text')
    // console.log(pastedValue,'pastedValue');
    if(pastedValue.length+1 > 3) {
    setPopOver(true)
    }
    if(pastedValue.length-1 === 0) {
      setPopOver(false)
      }
  }
  
  return (
    <>
      {show ? (
        <>
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity h-screen z-[99] ">
            <div className=" w-full mt-[3rem]">                          
                <div className=" mx-auto lg:w-[85%] w-[90%] xl:max-h-[80vh] md:max-h-[65vh] border overflow-y-auto bg-white relative " ref={searchRef}>
                  <div className=""> 
                  <div className="sticky top-0">                   
                    <button type="button"
                        className="lg:pt-4 pt-2 block rounded-md text-gray-900 hover:text-gray-700 focus:outline-none float-right lg:pr-4 pr-2"
                        onClick={() => onOptionClick()}
                      >
                        <span className="sr-only">Close</span>
                        <svg
                          className="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                    </button>
                    <input type="search"
                            value={searchInput}
                            // ref={searchRef}
                            id="globalSearchInput"
                            onChange={handleMobileChange}
                            onPaste={handleMobilePaste}
                            className="lg:hidden p-2 rounded-md w-[90%] text-[12px] outline-none shadow-none bg-inherit text-[#333333] opacity-[.55] leading-[17px] tracking-[-0.28] font-sans_book_body border m-3"
                            placeholder={searchLabel}
                            autoComplete="off"
                    />
                    </div>
                    <div className="mx-auto w-full gap-y-6 sm:gap-6 lg:pl-4 lg:pr-4 pl-2  md:pb-2 bg-white max-h-[70vh] lg:max-h-full overflow-y-auto overflow-x-auto scrollbar-remove">
                      {
                        searchResult !== null && searchResult !==0
                          ? 
                          searchResult['company']?.matches?.length === 0 && searchResult['investor']?.matches?.length === 0 && searchResult['l_advisor']?.matches?.length === 0 && searchResult['t_advisor']?.matches?.length === 0 ?
                          <div className='bg-white w-full flex items-center justify-center h-12'>
                                <p className="text-[12pt] lg:text-[15px] text-[#333333]">No Data Found</p>
                            </div>
                            :
                             searchResult['company']?.matches?.length === 0 && searchResult['acquirer']?.matches?.length === 0 && searchResult['l_advisor']?.matches?.length === 0 && searchResult['t_advisor']?.matches?.length === 0 ?
                          <div className='bg-white w-full flex items-center justify-center h-12'>
                                <p className="text-[12pt] lg:text-[15px] text-[#333333]">No Data Found</p>
                            </div>
                            :
                             searchResult['cfs']?.matches?.length === 0  ?
                          <div className='bg-white w-full flex items-center justify-center h-12'>
                                <p className="text-[12pt] lg:text-[15px] text-[#333333]">No Data Found</p>
                            </div>
                            :
                            searchResult['fund']?.matches?.length === 0 || searchResult['limited_partner']?.matches?.length === 0 || searchResult['re_fund']?.matches?.length === 0?
                            <div className='bg-white w-full flex items-center justify-center h-12'>
                                  <p className="text-[12pt] lg:text-[15px] text-[#333333]">No Data Found</p>
                              </div>
                            :                          
                          headerColumns?.map(column => {
                            if (searchResult === null) return <>No Data Found</>
                            if (searchResult[column.columnKey]?.matches?.length === 0) {
                              return <></>
                            } 
                            return <SearchColumn
                              searchResult={searchResult[column.columnKey]}
                              count={searchResult[column.columnKey]?.count}
                              countLabel={column.columnLabel}
                              title={column.columnName}
                              leading={column.columnLeading}
                              showCountLabel={searchInput?.length !== 0}
                              location={location}
                              prevPath={location?.pathname}
                              key={column.columnKey}
                              column={column}
                              onClick={onOptionClick}
                              type={type}
                              onLabelClick={(path) => onLableLinkClick(column.columnLabelLink(path), {
                                globalSearch: [{ id: 0, name: column.getFilterLabel(searchInput), searchTerm:searchInput, type:type, value: searchResult[column.columnKey]?.[column.allIdsKey] }],
                                globalMonthRange: [searchResult?.date_range?.from_date, searchResult?.date_range?.to_date],                                
                              },
                              column,
                              searchResult[column.columnKey]?.count
                              )}
                            />
                          })
                          : 
                          searchResult === 0 ? 
                          <div className='bg-white w-full flex items-center justify-center h-12'>
                                <p className="text-[12pt] lg:text-[15px] text-[#333333]">No Data Found</p>
                            </div>
                            :
                            <div className="hidden lg:block">
                           <div className='bg-white w-full flex items-center justify-center h-40'>
                                <Loader />
                            </div>
                            </div>
                      }
                    {
                      FetchRemaining.show
                        ? <div className="absolute top-0 left-0 w-full  h-full bg-slate-300 opacity-70 flex items-center justify-center flex-col">
                          <div><Loader /></div>
                          <div>
                            Fetching {FetchRemaining.current} / {FetchRemaining.total}
                          </div>
                        </div>
                        : <></>
                    }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          {/* </div> */}
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default Modal;
