import React, { useContext} from "react";
import Table from '../components/Table'
import ReInvestmentCard from '../components/ReInvestmentCard'
import RefineSearch from '../../../../Components/RefineSearchComponent'
import FilterTag from '../../../../Components/Investor/Tag'
import Tabbar from '../components/Tabbar'
import Constants from '../../../../Constants'
import ReAdvisorRefineSearch from '../../../../Components/RefineSearch/ReAdvisorRefineSearch'
import { useLocation } from 'react-router-dom'
import ReLayout from '../../../../Components/ReLayout'
import { PeFilterContext } from "../../../../Contexts/PeFilterContext";

const ReInvestmentsColumns = [
    { accessor: 'Investor', label: 'Investor' },
    { accessor: 'project_count', label: 'Projects',className:"justify-end" },
    { accessor: 'top_project_companies', label: 'Top projects cos based on investment size' },
    { accessor: 'last_invested_company_sort', label: 'Last Invested in' },
    { accessor: ' last_investment_at_sort', label: 'Last Investment Made' },
    { accessor: 'add', label: 'Add' },
]

/**
 * The ReInvestments function is a React component that renders a page for displaying and filtering
 * real estate investment data.
 * @returns a JSX element.
 */
function ReInvestments() {
    const {refineSearchFilters} = useContext(PeFilterContext);
    const location = useLocation();
    const {pathname} = useLocation();
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <>
        <ReLayout>
        <div className='flex min-h-screen h-screen flex-col'>
        {isFromGlobalSearch()? <></> :<ReInvestmentCard />}
            <div className={`h-full min-w-0 flex-1 border-t border-gray-200 flex ${isFromGlobalSearch() ? 'mt-[4.25rem] md:mt-[3rem]':''}`}>
                <section aria-labelledby="primary-heading" className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                    <FilterTag menu="re-fund"/>
                    <Tabbar
                        primaryTypeMasterId={Constants.primaryMasterIds.reId}
                        subTypeMasterId={Constants.primaryMasterSubIds.re_investor_investment_id}
                        typeId={Constants.moduleIds.investorsId}
                        exportFileName={Constants.exportNames.re_investor_investments}
                        menu="re-investments"
                    />
                    <div className='overflow-y-auto'>
                        <Table order="desc" orderBy=" last_investment_at_sort" columns={ReInvestmentsColumns} category="re-investments" sub_type_master_id={Constants.subCategoryIds.re_investor_investment_id}/>
                    </div>
                </section>
                <aside className="order-first block flex-shrink-0">
                    <div className="relative flex h-[100%] mb-[4.25rem] flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                        {/* Your content */}
                        <RefineSearch>
                            <ReAdvisorRefineSearch customPathname={location.pathname}/>
                        </RefineSearch>
                    </div> 
                </aside>

            </div>
        </div>
        </ReLayout>
        </>
    )
}

export default ReInvestments