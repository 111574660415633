import React, { useState,useContext } from 'react'
import { PeFilterContext } from "../../../Contexts/PeFilterContext";
import Card from '../../../Components/Advisor/AdvisorPageCard/Card'
import Layout from '../../../Components/VC/VcTAdvisorLayout'
import MainMenu from '../../../Components/VC/TransactionMainmenu'
// import RefineSearch from '../../../Components/RefineSearch'
import Table from '../../../Components/TransactionAdvisor/T_TableComponent'
import FilterTag from '../../../Components/Tag'
import RefineSearch from '../../../Components/RefineSearchComponent'
import AdvisorExistsRefineSearch from '../../../Components/RefineSearch/AdvisorExistsRefineSearch'
import Constants from '../../../Constants'
import { useLocation } from 'react-router-dom'

/* The `Advisor` function is a React component that renders a page for displaying advisor information. */
function Advisor() { 
    const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();

    const {refineSearchFilters,isMobile} = useContext(PeFilterContext);

    const { pathname } = useLocation(); 
      const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <>
        <Layout active={4}>
                <div className="flex h-screen flex-col">
                    {/* Top nav*/}
                    {isFromGlobalSearch() ? "" :    <div className="hidden lg:block"> <Card /></div>}

                    {/* Bottom section */}
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 flex":"min-w-0 flex-1 border-t border-gray-200 flex"}>
                            {/* Primary column */}
                            <section
                                className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-[#EDEDED]"
                            >
                                {/* Your content */}
                                <FilterTag menu="tadvisor-exit-pm-vc"/>                                
                                <MainMenu type="vc_pm_exit" active={2} menu="tadvisor" subMenu = "exist-pm-pm" primaryTypeMasterId={Constants.primaryMasterIds.vcId}
                                subTypeMasterId={Constants.primaryMasterSubIds.vc_advisors_pm_id}
                                typeId={Constants.moduleIds.t_advisor_id}
                                advisor_type="T"
                                exportFileName={Constants.exportNames.vc_t_advisor_exit_pm}/>
                                <div className='overflow-y-auto'>
                                    <Table menu ="vc_pm_advisor" advisor_type="T" sub_type_master_id={Constants.subCategoryIds.vc_tadvisor_exitspm_id}/>
                                    </div>                                   
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className={`order-first block flex-shrink-0 ${isMobile ? "pt-14" : ""}`}>
                                <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch> <AdvisorExistsRefineSearch customPathname={location.pathname}/> </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>                    
                </div>
            </Layout>
        </>
    )
}

export default Advisor
