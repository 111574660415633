import React, {useContext } from "react"
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/InvestorLayout'
import MainMenu from '../../../Components/Investor/MainMenu'
import Card from '../../../Components/Investor/InvestorPageCard/Card'
import Table from '../../../Components/Investor/TableComponent'
// import {PE_TABLE_QUERY} from '../../../Queries/Pe';

import RefineSearch from '../../../Components/RefineSearchComponent'
import InvestorExistRefineSearch from '../../../Components/RefineSearch/InvestorExistRefineSearch'
import FilterTag from '../../../Components/Investor/Tag'
// import chatPopupIcon from '../../../images/chatPopupIcon.png'
// import ChatPopup from '../../../Components/ChatPopup'
import Constants from '../../../Constants'

import { PeFilterContext } from "../../../Contexts/PeFilterContext";

/**
 * The function `Public_PE_VC` is a React component that renders a layout with a primary column and a
 * secondary column, and includes various components such as a card, a filter tag, a main menu, a
 * table, and a refine search component.
 * @returns The function `Public_PE_VC` returns JSX elements that make up the layout and components of
 * a page.
 */
function Public_PE_VC() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();

    const {refineSearchFilters,isMobile} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <Layout>
            <div className='h-screen flex flex-col'>
            {isFromGlobalSearch() ? ""
               : <div className="hidden lg:block"><Card card2={window.location.pathname ?(window.location.pathname =='/pe/investors/exits/pm/pe-vc-investments'? 'PE-VC Exits':''):''}/>
               </div>
            }
            <div className='flex flex-0 min-h-0'>
                    <div className="h-full min-w-0 flex-1 border-t border-gray-200 lg:flex">
                        {/* Primary column */}
                        <main className={isFromGlobalSearch() ?"mt-[3rem] min-w-0 flex-1 border-t border-gray-200 flex":"min-w-0 flex-1 border-t border-gray-200 flex"}>
                            <section
                                aria-labelledby="primary-heading"
                                className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last"
                            >
                                <FilterTag menu ="investor"/>
                                <MainMenu active={2} menu="public_pevc" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_pm_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.pe_investor_exit_pm}/>
                                <div className='overflow-y-auto'>
                                    <Table menu="public_pevc" sub_type_master_id={Constants.subCategoryIds.pe_investor_exitspm_id}/>
                                </div>

                                {/* <Outlet /> */}
                                {/* Your content */}
                            </section>
                        </main>
                    </div>
                    {/* Secondary column (hidden on smaller screens) */}
                    <aside className={`order-first block flex-shrink-0 ${isMobile ? "pt-14" : ""}`}>
                        <div className="relative flex flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                            {/* Your content */}
                            <RefineSearch><InvestorExistRefineSearch customPathname={location.pathname}/> </RefineSearch>
                        </div>
                    </aside>
                        
                    </div>
            </div>
        </Layout>
    )
}

export default Public_PE_VC
