import React from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../../../Components/ReLayout'
import Table from '../../../Components/REInvestor/ReInvestorFunds/TableComponent'
import RefineSearch from '../../../Components/RefineSearchComponent'
import MainMenu from '../../../Components/REInvestor/ReInvestorFunds/FundsMainmenu'
import FundsBreadCrum from '../../../Pages/Investor/FundsBreadCrum'
import FilterTag from '../../../Components/Tag'
import Constants from '../../../Constants'
import ReInvestorFundRefineSearch from '../../../Components/RefineSearch/ReInvestorFundRefineSearch';
/**
 * The `InvestorFunds` function renders a page layout with a main section and a sidebar, displaying a
 * table of investor funds and providing options for filtering and refining the search.
 * @returns The InvestorFunds component is returning a JSX structure that includes a Layout component
 * with multiple nested elements such as divs, sections, and asides. It also includes other custom
 * components such as FilterTag, FundsBreadCrum, MainMenu, Table, RefineSearch, and
 * ReInvestorFundRefineSearch.
 */
const InvestorFunds = () => {
    const location = useLocation(); 
    
    return (
        <div>
            <>
                <Layout>
                    <div className="flex h-screen flex-col">
                        <div className="flex min-h-0 flex-1 overflow-hidden">                           
                            <main className="min-w-0 flex-1 border-t border-gray-200 flex  sm:full mt-[4.25rem] md:mt-[3rem]">
                                <section className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]">
                                    <FilterTag />
                                    <FundsBreadCrum menu="reFund" subMenu="re" />
                                    <MainMenu menu="investor" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                        subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_funds_id}
                                        typeId={Constants.moduleIds.investorsId} />
                                    <div className="overflow-y-auto">
                                        <Table menu="inv_funds" subMenu="fund" sub_type_master_id={Constants.subCategoryIds.re_investor_funds_id}/>
                                    </div>
                                </section>
                                {/* Secondary column (hidden on smaller screens) */}
                                <aside className="order-first block flex-shrink-0">
                                    <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                        <RefineSearch>
                                            <ReInvestorFundRefineSearch customPathname={location.pathname}/>
                                        </RefineSearch>
                                    </div>
                                </aside>
                            </main>
                        </div>                        
                    </div>
                </Layout>
            </>
        </div>
    )
}

export default InvestorFunds