import React, { useContext } from "react";
import Layout from '../../Components/VC/VcCompanyLayout'
import MainMenu from '../../Components/VC/Mainmenu'
import Card from '../../Components/ListPageCard/ExitCard'
import Table from '../../Components/TableComponent'
// import {PE_TABLE_QUERY} from '../../Queries/Pe';

import RefineSearch from '../../Components/RefineSearchComponent'
import FilterTag from '../../Components/Tag'
// import chatPopupIcon from '../../images/chatPopupIcon.png'
// import ChatPopup from '../../Components/ChatPopup'
import Constants from '../../Constants'
import ExitRefineSearch from '../../Components/RefineSearch/ExitRefineSearch'
import { useLocation } from 'react-router-dom'
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { Switch, Tooltip } from "antd";
import { UserContext } from "../../Contexts/UserContext";
import ToggleDealsViewButton from "../../Components/ToggleDealsView";

/**
 * The function `ExistsViaPMVCPM` renders a layout with a top navigation bar, a main section with a
 * primary column and a secondary column, and a bottom section with a table and a refine search
 * component.
 * @returns The function `ExistsViaPMVCPM` returns JSX elements wrapped in a `Layout` component. The
 * returned JSX represents a page layout with a top navigation bar, a main content area divided into
 * two columns (primary and secondary), and a bottom section. The primary column contains a filter tag,
 * a main menu, and a table component. The secondary column (hidden on smaller screens) contains a
 */
function ExistsViaPMVCPM() {
    // const [showModal, setShowModal] = React.useState(false);
    const location = useLocation();
    const { pathname } = useLocation(); 
 
    const { refineSearchFilters, dealsView, setDealsView, switchValue, setSwitchValue,isMobile  } = useContext(PeFilterContext);
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const { isDirectoryUser } = useContext(UserContext);

    const dealsViewHandler = (checked) =>{
        setSwitchValue(checked);
        if(checked){ setDealsView(true) }else setDealsView(false);
    }
   
    return (
        <Layout>
        <div className="flex min-h-screen h-screen flex-col">
                    {/* Top nav*/}
                    {isFromGlobalSearch () ? "" : <div className="hidden lg:block"><Card /> </div>}
                    {/* Bottom section */}
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Narrow sidebar*/}
                        {/* Main area */}
                        <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 flex":"min-w-0 flex-1 border-t border-gray-200 flex"}>
                            {/* Primary column */}
                            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                                <div className="mr-[4.25rem] flex justify-between items-center">
                                <FilterTag />
                                <ToggleDealsViewButton />
                                </div>
                                {/* Your content */}
                                <MainMenu active={2} menu="exit-pm-vc" primaryTypeMasterId={Constants.primaryMasterIds.vcId}
                                subTypeMasterId={Constants.primaryMasterSubIds.vc_pm_id}
                                typeId={Constants.moduleIds.companiesId}
                                exportFileName={Constants.exportNames.vc_company_exit}/>
                                <div className='overflow-y-auto'>
                                    <Table menu="exit-pm-vc" sub_type_master_id={Constants.subCategoryIds.vc_exitspm_id}/>
                                </div>
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside cclassName={`order-first block flex-shrink-0 ${isMobile ? "pt-14" : ""}`}>
                                <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch>
                                    <ExitRefineSearch typeId={Constants.primaryMasterSubIds.vc_pm_id} customPathname={location.pathname}/>
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>
                    
                </div>
        </Layout>
    )
}

export default ExistsViaPMVCPM
