import { useState , useContext,useEffect} from "react";
import { FaFilter } from "react-icons/fa";
import { IoClose  } from "react-icons/io5";
import { BsArrowRightSquareFill } from "react-icons/bs";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";
import { PeFilterContext } from '../../Contexts/PeFilterContext';
/**
 * The RefineSearch component is a collapsible RefineSearch that contains a title, a toggle button, and a content
 * area for children components.
 * @returns a JSX element.
 */
export default function Sidebar({children}) {
    // const [open, setOpen] = useState(false);
    const {dropdown,setDropdown,isMobile,setIsMobile,open, setOpen} = useContext(PeFilterContext);

    const handleToggle = () => {
        setDropdown(!dropdown);
        console.log(dropdown , "jgjhg")
    };
    useEffect(()=>{
        if (window.innerWidth < 800) {
            setOpen(true);
            setIsMobile(true);
        }    },[])
    return (
        <>
                <div
                    className={`${
                        open ? (isMobile ? "hidden" : "w-12") : "w-[100vw] md:w-60"
                    } flex flex-col h-full shadow duration-300`}
                >
                    <div className="space-y-3 h-screen">
                        <div className="flex items-center pl-1 pr-3 pt-3 justify-between">
                            <h2 className="text-xl font-bold text-white">
                                <div
                                    style={{ display: !open ? "flex" : "none" }}
                                    className="text-[16px] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer flex-row"
                                    onClick={handleToggle}
                                >
                                    <span className="mt-[2px]">
                                        {dropdown ? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                                    </span>
                                    <span className="ml-1">REFINE SEARCH</span>
                                </div>
                            </h2>
                            {!isMobile && (
                                <button onClick={() => setOpen(!open)}>
                                    <BsArrowRightSquareFill
                                        style={{
                                            transform: !open ? "rotate(180deg)" : "none",
                                            height: 24,
                                            width: 24,
                                            color: "#bc8b20",
                                            borderRadius: 4,
                                        }}
                                    />
                                </button>
                            )}
                        </div>

                        <div className="flex-1" style={{ display: !open ? "block" : "none" }}>
                            {children}
                        </div>
                    </div>
                </div>

                {isMobile && (
                    <button
                        className={`fixed z-50 bottom-16 right-3 mb-2 drop-shadow-md rounded-full bg-[#bc8b20] ${!open ? 'p-[12px]' : 'p-[14px]'}`}
                        onClick={() => setOpen(!open)}
                    >
                    {!open ? <IoClose   color="#ffffff" size={25} /> : <FaFilter   color="#ffffff" size={21} />}
                    </button>
                )}

                {!isMobile && <div className="container mx-auto mt-12"></div>}
            </>
    );
}