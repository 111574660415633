import React, { useContext, useState, Fragment, useEffect } from 'react';
import { BrowserRouter as Router, Link, useParams, useNavigate, createSearchParams, useLocation } from "react-router-dom";
import Autocomplete from 'react-autocomplete'
import axios from 'axios'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon } from '@heroicons/react/outline'
import { CompareCompanyContext } from '../Contexts/CompareCompanyContext'
import { compareCompany, compareCompanyList, compareInvestor, compareAdvisor ,compareCfs, maCompareCompany,maCompareAcquirer,maCompareAdvisor} from '../Config/config'
import NotificationComponent from '../Utils/Notification'
import CompanyLogo from '../Components/CompanyLogo';
import RandomLogo from '../Components/RandomLogo'
import CoinImg from '../images/Combined Shape_14/png/Combined Shape.png';
import LocationImg from '../images/Combined Shape_20/png/Combined Shape.png';
import PlateImg from '../images/Combined Shape_21/png/Combined Shape.png';
import CalenderImg from '../images/Shape_11/png/Shape.png';
import DolorSignImg from '../images/Combined Shape_13/png/Combined Shape.png';
import ChatIcon from '../images/Group 13/png/Group 13.png';
import CompanyImg from '../images/barraiser-logo.png';
import CancelImg from '../images/Group 25.png';
import Search from '../images/Shape_13/png/Shape.png'
import '../Components/HeaderComponent.css'
import { SearchMenuContext } from "../Contexts/SearchMenuContext";
import PrimaryButton from '../Components/shared/PrimaryButton';
import OutlineButton from '../Components/shared/OutlineButton';
import DebounceSelect from '../Components/shared/DebounceSelect';
import { AiFillCloseCircle } from 'react-icons/ai'
import { UserContext } from '../Contexts/UserContext';
import useModule from "../hooks/useModule";
import { routeNames } from '../routeSegments';
import Constants from '../Constants';
import { valueToObjectRepresentation } from '@apollo/client/utilities';
import { toast } from 'react-toastify';


/** It is a dialog component that displays a
search box for selecting entities. The component takes several props including `fetchOptions`,
`currentPage`, `onselect`, `open`, and `setOpen`. */
export const CompareSearch = ({ fetchOptions, currentPage, onselect, open, setOpen }) => {
    return <div>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-50" onClose={setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 "
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transition-all sm:my-8 sm:max-w-sm sm:w-full">
                                <div>
                                    <div className="text-center ">
                                        <Dialog.Title as="h3" className="bg-[#BC8B20] text-[#FFFFFF] flex items-center justify-between font-sans_book_body text-[18px] w-full h-12 px-4">
                                            {currentPage == "company" ? "Select Company" : currentPage == "acquirer" ? "Select Acquirer" : currentPage == "investor" || currentPage == "investorAngel" || currentPage == "investorIncubation" ? "Select Investor" : currentPage == "l_advisor" ? "Select Legal Advisor" :currentPage=="cfs"?"Select Company": "Select Transaction Advisor"}
                                            <AiFillCloseCircle className='cursor-pointer' size={24} color='white' onClick={() => setOpen(false)} />
                                        </Dialog.Title>
                                        <div className="hidden md:block p-4 py-8">
                                            <div className="relative grid border border-[#CCCCCC] rounded-[10px]  w-full">
                                                <DebounceSelect
                                                    fetchOptions={fetchOptions}
                                                    // mode="multiple"
                                                    showSearch={true}
                                                    placeholder=""
                                                    defaultActiveFirstOption={false}
                                                    showArrow={false}
                                                    onChange={(selected) => {
                                                        setOpen(false)
                                                        onselect(selected)
                                                    }}
                                                />
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </div>;
}


/** The above code is a React component called `ComparePopUp`. It is responsible for displaying a pop-up
window that allows users to compare different entities (companies, investors, advisors, etc.) based
on their IDs. */
const ComparePopUp = (props) => {
    const { companyDetail, setCompanyDetail, companyHeader, setCompanyHeader, compareCompanyId, setCompareCompanyId, currentPage } = useContext(CompareCompanyContext);
    console.log(currentPage, "1234567890")
    const { companyId, investorId,acquirerId } = useParams();
    let companyProfileId = parseInt(companyId)
    let investorProfileId = parseInt(investorId)
    // let cfsProfileId = parseInt(cprofileId)
    const [open, setOpen] = useState(false)
    const [value, setValue] = useState('');
    const [selectedCompany, setSelectedCompany] = useState(null)
    const [CompanyId, setCompanyId] = useState(null)
    const [showNotification, setShowNotification] = useState(false)
    const PEHeaderSearchMenu = useContext(SearchMenuContext);
    const [FilteredData, setFilteredData] = useState(PEHeaderSearchMenu.data);
    const [compareCompanyApiStatus, setCompareCompanyApiStatus] = useState(false)
    const [popOver, setPopOver] = useState(false);
    const { getToken, logout } = useContext(UserContext);
    const { primaryMasterId, isMA,isCFS } = useModule();
    const location = useLocation();





    useEffect(() => {
        let id = currentPage == "investor" || currentPage == "investorAngel" || currentPage == "investorIncubation" ? investorProfileId : currentPage == "acquirer" ? acquirerId : companyProfileId;
        if(props.entityId != null){
            id = props.entityId
        }
        setCompareCompanyId(oldArray => [id])
        addCompanyDetail(id)
    }, [])

   /**
    * The function `addCompanyDetail` makes a POST request to an API endpoint with a specific request
    * body based on the current page and whether the user is an MA (mergers and acquisitions) advisor,
    * and then updates the state with the response data.
    */
    const addCompanyDetail = (companyId) => {
        let requestBody = ''
        isMA && currentPage == "l_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "L",
        }
        :
        isMA && currentPage == "t_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "T",
        }
        :
        isMA && currentPage == "company" ?
        requestBody = {
            "company_id": companyId,
            "is_detailed": true
        }
        :
        isMA && currentPage == "acquirer" ?
        requestBody = {
            "acquirer_id": companyId,
            "is_detailed": true,
            "type":4
        }
        :
        currentPage == "l_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "L",
        }
        :
        currentPage == "t_advisor" ?
        requestBody = {
            "advisor_id": companyId,
            "is_detailed": true,
            "advisor_type": "T",
        }
        :
        currentPage == "company" ?
        requestBody = {
            "company_id": companyId,
            "is_detailed": true
        }
        :
        currentPage == "cfs"?
        requestBody = {
            "company_id": companyId,
            "is_detailed": true
        }

        :
        requestBody = {
            "investor_id": companyId,
            "is_detailed": true
        }
         axios(`${isMA && currentPage == "company" ? maCompareCompany : isMA && currentPage == "acquirer" ? maCompareAcquirer : isMA && currentPage == "l_advisor" || isMA && currentPage == "t_advisor" ? maCompareAdvisor : currentPage == "company" ? compareCompany :currentPage == "cfs"? compareCfs : currentPage == "investor" ? compareInvestor : compareAdvisor}`, {
            method: "POST",
            data: requestBody,
            headers: {
                "Content-Type": "application/json",
                "Authorization": getToken()
            },
        })
            .then((response) => {
                if (response.data.message == "Success") {
                    setCompanyHeader(response.data.compareData.header)
                    setCompanyDetail(current => [...current, response.data.compareData.data])
                    console.log(response.data.compareData.data, "addCompanyDetail");
                }
            })
            .catch((error) => {
                if (error?.response?.status == Constants.sessionTerminatedStatus 
                    || error?.response?.status == Constants.InvalidTokenStatus
                ){
                    toast.error(`Session Expired!`);
                    setTimeout(() => logout(), 1000)
                }
                console.log("API not working - compare company ", error);
            });
    }
    console.log(companyDetail, "addCompanyDetail");
    console.log(companyHeader, "addCompanyDetailHeader");

    const addCompareCompany = () => {
        setOpen(!open)
    }
    const addCompanyOnchange = (event) => {
        setValue(event.target.value)
    }
    const addCompanyOnselect = (val) => {
        FilteredData.filter(function (el) {
            return (
                el.label == val ? setCompanyId(el.id) : ""
            )
        }
        );
        setValue(val)
        addCompanyDetail(val.id)
        setOpen(false)
        setShowNotification(true)
        setValue('')
        setCompareCompanyId(oldArray => [...oldArray, val.id])
        console.log(compareCompanyId, compareCompanyId)
    }
    const addCompany = (companyId) => {
        addCompanyDetail(value)
        setSelectedCompany(CompanyId)
        setCompareCompanyId(oldArray => [...oldArray, CompanyId])
        setOpen(false)
        setShowNotification(true)
        setValue('')
    }
    console.log(compareCompanyId, "as")

    const onchangeSearchMenu = (e) => {
        setValue(e.target.value);
        setCompareCompanyApiStatus(false)
        if (e.target.value?.length >= 3) {
            let resultArray = [];
            setFilteredData([]);
            axios(`${compareCompanyList}`, {
                method: "POST",
                data: {
                    "type": currentPage == "company" ? "company" :currentPage == "cfs" ? "cfs" : currentPage == "investor" || currentPage == "investorAngel" || currentPage == "investorIncubation" ? "investor" :currentPage == "acquirer" ?"acquirer": currentPage == "l_advisor" ? "l_advisor" : "t_advisor ",
                    "name": e.target.value
                },
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getToken()
                },
            })
                .then((response) => {
                    if (response.data.message == "Success") {
                        setCompareCompanyApiStatus(response.data.message == "Success" && true)
                        response.data?.data?.map((arr, i) => {
                            resultArray.push({ id: arr.id, name: arr.entity_name, label: arr.entity_name });
                        });
                        setFilteredData(resultArray);
                    }
                })
                .catch((error) => {
                    console.log("API not working - compare company ", error);
                });
        }
    };


    const fetchOptions = async (value) => {
        try {
            const response = await axios(`${compareCompanyList}`, {
                method: "POST",
                data: {
                    "type": primaryMasterId,
                    "module_type": currentPage,
                    "name": value,
                    selected_id: compareCompanyId,
                },
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": getToken()
                },
            })
            if (response.status === 200) {
                return response.data?.data?.map((arr, i) => {
                    return { value: arr.id, label: arr.entity_name };
                });
            } else {
                return []
            }
        } catch (error) {
            if (error?.response?.status == Constants.sessionTerminatedStatus) {
                logout()
            }
        }
    }

    const onselect = (value) => {
        addCompanyDetail(value)
        setCompareCompanyId(oldArray => [...oldArray, value])
    }

    /**
     * The `useNavigateParams` function is a custom hook in React that allows for easy navigation with
     * parameters and state.
     */
    const useNavigateParams = () => {
        const navigate = useNavigate();

        return (pathname, params, state) => {
            const path = {
                pathname,
                search: createSearchParams(params).toString(),
            };
            navigate(path, { state });
        };
    };

    const navigate = useNavigateParams();


    /**
     * The function `goToCompare` navigates to a specific route based on the current page and entity
     * ID.
     */
    const goToCompare = () => {
        let id;
        if(props.entityId != null){
            id = props.entityId
        }
        navigate(currentPage == "company" 
            ? routeNames.compareCompanyById(companyProfileId)
            : currentPage == "investor"
                ? routeNames.compareInvestorById(investorProfileId)
                : currentPage == "l_advisor"
                    ? routeNames.compareLegalAdvisorById(id??companyProfileId)
                    :currentPage =="t_advisor"?
                    routeNames.compareTransactionById(id??companyProfileId)

                    :currentPage =="cfs"?
                    routeNames.compareCompanyById(companyProfileId)
                    :currentPage =="acquirer"?
                    routeNames.compareAcquirerById(acquirerId)
                    :"",
            { type: primaryMasterId },
            { prevPath: location.pathname });

    }

    return (
        <div>
            {showNotification ? <NotificationComponent
                message={(currentPage == "company" ? "Company" :currentPage == "cfs"? "cfs": currentPage == "investor" || currentPage == "investorAngel" || currentPage == "investorIncubation" ? "investor" :currentPage == "acquirer" ?"Acquirer": currentPage == "l_advisor" || currentPage == "t_advisor" ? "Advisor" : '') + " Added Successfully"}
            /> : ""}
            <CompareSearch currentPage={currentPage} fetchOptions={fetchOptions} onselect={onselect} open={open} setOpen={setOpen} />
            <div className='fixed top-0 left-0 right-0 h-screen w-screen  z-50'>
                <div onClick={() => props.onClose(false)} className='bg-black opacity-40  z-10 h-screen w-screen'></div>
                <div className=' bg-[#ECECEC] fixed left-0 right-0 bottom-0'>
                    {companyDetail?.length > 0 ?
                        <>
                            <div className='flex py-2 justify-center space-x-4 object-cover'>
                                {companyDetail?.map((detail, i) => {
                                    console.log(detail,'vxjuvcuejvc');
                                    return (
                                        <div className=' bg-[#FFFFFF] w-[15rem] p-1 rounded-[4px] shadow-[0_1px_3px_0_rgba(0,0,0,0.15)]'>
                                            {/* <div className='flex justify-between'>
                                <div className='flex'>
                                    <button
                                        className='bg-[#FFECB9] mx-2 mt-2 pr-2 pl-2 rounded-[9.5px] text-[#645326] pt-0 pb-0 text-[12px] leading-[14px] w-[50px] h-[19px] '>
                                        PE-VC
                                    </button>
                                </div>
                            </div> */}
                                            <div className='flex items-center space-x-2 mb-2'>
                                                {/* {detail[1]?.icon_path == "default.png" || detail[1]?.icon_path == "/default.png" || !detail[1]?.icon_path ?
                                                    <RandomLogo name={detail[1].value} />
                                                    :
                                                    <CompanyLogo name={detail[1].value} page="company page" logo={detail[1]?.icon_path} />
                                                } */}
                                                {/* <img className='w-[24px] h-[24px] ml-2 '
                                    src={detail.logo} alt='' /> */}
                                                <div className=''>
                                                    <h3
                                                        className='float-left leading-[18px] tracking-[-0.28px] text-[#333333] text-[15px] '>
                                                        {detail[isCFS?2 :1]?.value}
                                                    </h3>
                                                </div>
                                            </div>
                                            <div className='justify-between pt-0 mt-1 grid grid-cols-2'>
                                                {detail?.map((data, j) => {
                                                    if (data?.display_mode == "both") {
                                                        console.log('data-0989',data);
                                                        return (
                                                            j == 0 || j == 1 ? ""
                                                                :
                                                                <div className='pl-2 '>
                                                                    <div className='flex mb-2'>
                                                                        <img src={data?.icon_path == "date" ? CalenderImg : data?.icon_path == "in_india_since" ? CalenderImg : data?.icon_path == "total_funding" ? DolorSignImg : data?.icon_path == "revenue" ? PlateImg : data?.icon_path == "to_investors" ? PlateImg : data?.icon_path == "investor_city" ? LocationImg : data?.icon_path == "advisor_city" ? LocationImg : CoinImg}
                                                                            alt='' className='w-[20px] h-[20px]' />

                                                                        <p
                                                                            className='ml-1 mt-1 font-semibold text-[12px] tracking-[-0.3px] leading-[14px] text-[#333333] h-[14px] '>
                                                                            {data?.value &&  data?.value !=0 && data?.value !='--' ? data?.value : "--"}
                                                                        </p>
                                                                    </div>
                                                                </div>


                                                        )
                                                    }
                                                })}

                                            </div>

                                        </div>
                                    )
                                })


                                }
                                {!selectedCompany ?
                                    <div onClick={addCompareCompany} className='cursor-pointer bg-[#FFFFFF] w-[13rem] p-1 rounded-[4px]  shadow-[0_1px_3px_0_rgba(0,0,0,0.15)] flex items-center justify-center'>
                                        <p className=''> {currentPage == "company" ? "Add Company" : currentPage == "investor" || currentPage == "investorAngel" || currentPage == "investorIncubation" ? "Add Investor" : currentPage == "l_advisor" ? "Add Legal Advior": currentPage == "t_advisor"? "Add Transaction Advisor" : currentPage=="cfs"?"Add Company" : currentPage=="acquirer"?"Add Acquirer":" "}</p>
                                    </div>
                                    :
                                    ""}

                                <button onClick={goToCompare} className='w-[8rem] col-span-1 text-white text-center p-1 bg-[#BC8B20] rounded-[4px] shadow-[0_1px_3px_0_rgba(0,0,0,0.15)] '>
                                    <p className="">Compare </p>
                                </button>
                            </div>
                        </> :
                        <div className="w-full">
                            <center>
                                <div role="status" className=" h-[10rem]">
                                    <svg
                                        className="relative top-[4rem] inline mr-2 w-12 h-12 text-gray-200 animate-spin dark:text-gray-600 fill-[#CA8A24]"
                                        viewBox="0 0 100 101"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                            fill="currentColor"
                                        />
                                        <path
                                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                            fill="currentFill"
                                        />
                                    </svg>
                                </div>
                            </center>
                        </div>
                    }
                </div>
            </div>




            {/* <!--mobile view--> */}
            {/* <div className='md:hidden  bg-white fixed z-10 left-0 right-0 bottom-0'>
                <div className='grid grid-cols-3 gap-4 shadow-[0_1px_3px_0_rgba(0,0,0,0.15)] '>
                    <div className=''>
                        <div className=' bg-[#FFFFFF]  p-1 rounded-[4px] border-r '>
                            <img className='float-right' src={CancelImg}></img>
                            <img className='w-[40pt] h-[40pt] ml-5 mt-5' src={CompanyImg}></img>
                        </div>
                    </div>
                    <div className=''>
                        <div className=' bg-[#FFFFFF] p-1 rounded-[4px] border-r '>
                            <img className='float-right' src={CancelImg}></img>
                            <img className='w-[40pt] h-[40pt] ml-5 mt-5' src={CompanyImg}></img>
                        </div>
                    </div>



                    <div>
                        <div className=' bg-[#FFFFFF] p-1 rounded-[4px]'>
                            <p className='mt-5 '>{currentPage == "company" ? "Add Company 2" :currentPage == "acquirer" ?"Add Acquirer 2": currentPage == "investor" || currentPage == "investorAngel" || currentPage == "investorIncubation" ? "Add Investor 2" : currentPage == "l_advisor" ? "Add Legal Advisor 2" : "Add Transaction Advisor 2"}</p>
                        </div>
                    </div>

                </div>
                <Link to={`/compare-companies/${companyProfileId}`} className='w-full  text-white text-center bg-[#BC8B20] rounded-[4px]  '>
                    <p className="text-white text-center py-3 bg-[#BC8B20]">Compare </p>

                </Link>

            </div> */}


        </div>


    )
}

export default ComparePopUp