import React, {  useContext } from "react"
import { useLocation } from 'react-router-dom'
import Card from '../../Components/Investor/InvestorPageCard/Card'
import Layout from '../../Components/InvestorLayout'
import MainMenu from '../../Components/Investor/MainMenu'
// import RefineSearch from '../../Components/RefineSearch'
import Table from '../../Components/Investor/TableComponent'
import FilterTag from '../../Components/Investor/Tag'
import RefineSearch from '../../Components/RefineSearchComponent'
import InvestorRefineSearch from '../../Components/RefineSearch/InvestorRefineSearch'
import Constants from '../../Constants'
import { PeFilterContext } from "../../Contexts/PeFilterContext";


/* The code defines a functional component named `Investor`. */
const Investor = () => { 
    const location = useLocation();    
    const {refineSearchFilters,isMobile} = useContext(PeFilterContext);
    const { pathname } = useLocation(); 

    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
    <div>  
        <>
            <Layout>
                <div className="flex h-screen flex-col">
                    {/* Top nav*/}                 
               <div>
                {isFromGlobalSearch() ?
                ""
                :
             <div className="hidden lg:block">   
             <Card card2={window.location.pathname ? (window.location.pathname == '/pe/investors/pe-vc-investments' ? 'PE-VC Investments' : '') : ''} />
            </div>
                }
                </div>   
                    {/* Bottom section */}
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Narrow sidebar*/}
                        {/* Main area */} 
                        <main className= {isFromGlobalSearch() ? "mt-[3rem] min-w-0 flex-1 border-t border-gray-200 flex ":"min-w-0 flex-1 border-t border-gray-200 flex"}>
                            {/* Primary column */}
                            <section
                                className="flex h-full min-w-0 flex-1 flex-col  lg:order-last bg-[#EDEDED]"
                            >
                                {/* Your content */}
                                <FilterTag menu="investor" />                                
                                    <MainMenu active={1} menu="investor" primaryTypeMasterId={Constants.primaryMasterIds.peId}
                                subTypeMasterId={Constants.primaryMasterSubIds.pe_vc_investments_id}
                                typeId={Constants.moduleIds.investorsId}
                                exportFileName={Constants.exportNames.pe_investor_investment}/>
                                    <div className=' overflow-y-auto'>
                                        <Table menu="investor" sub_type_master_id={Constants.subCategoryIds.pe_investor_investments_id}/> 
                                    </div>                                
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className={`order-first block flex-shrink-0 ${isMobile ? "pt-14" : ""}`}>
                                <div className="relative flex flex-col border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                     
                                     <RefineSearch>
                                        {/* <InvestmentRefineSearch /> */}
                                        <InvestorRefineSearch customPathname={location.pathname}/>
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>
                    
                </div>
            </Layout>
        </>
    </div>
    )
}

export default Investor
