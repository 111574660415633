import React, { useContext, useEffect, useState, Suspense } from "react";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import { TableHeaderContext } from "../../Contexts/TableHeaderContext";
import IndustryFilter from "../../Components/RefineSearchComponents/IndustryFilter";
import SectorFilter from "../../Components/RefineSearchComponents/SectorFilter";
import StageFilter from "../../Components/RefineSearchComponents/StageFilter";
import RoundFilter from "../../Components/RefineSearchComponents/RoundFilter";
import InvestorTypeFilter from "../../Components/RefineSearchComponents/InvestorTypeFilter";
import InvestorHeadquartersFilter from "../../Components/RefineSearchComponents/InvestorHeadquartersFilter";
import FirmTypeFilter from "../RefineSearchComponents/FirmTypeFilter";
import Switch from "../RefineSearchComponents/AndOrToggleSwtich";
import Tags from "../RefineSearchComponents/Tags";
import InvestorCityTag from '../RefineSearchComponents/InvestorCityTag';
// import InvestorDealFilter from '../RefineSearchComponents/InvestorDealFilter'
import axios from "axios";
import { UserContext } from "../../Contexts/UserContext";
import { filterSectorByIndutry, tagList } from "../../Config/config";
import { useLocation, useNavigate } from "react-router-dom";
import { TableDataContext } from "../../Contexts/TableDataContext";
import Constants from "../../Constants";
import { Tooltip } from "antd";
import { FaInfoCircle } from "react-icons/fa";
import DefinitionModal from "../TagDefinitionModal";
import ToggleButton from "../RefineSearchComponents/ToggleAndOrSwitch";
import LAdvisor from '../RefineSearchComponents/lAdvisor';
import TAdvisor from '../RefineSearchComponents/tAdvisor';
import TargetCompany from '../RefineSearchComponents/TargetCompany';
import Investor from "../RefineSearchComponents/Investor";
import { IoMdArrowDropdown, IoMdArrowDropright } from "react-icons/io";

// import InvestorDealRangeFilter from "../RefineSearchComponents/InvestorDealRangeFilter";
const InvestorDealRangeFilter = React.lazy(() => import("../RefineSearchComponents/InvestorDealRangeFilter"));
const InvestorDealFilter = React.lazy(() => import('../RefineSearchComponents/InvestorDealFilter'));

/**
 * The InvestmentRefineSearch function is a React component that renders a form for refining search
 * filters .
 * @returns The function `InvestmentRefineSearch` returns a JSX element.
 */
export default function InvestmentRefineSearch({ customPathname }) {
    const {
        onErrorHandler,dropdown, isMobile,setOpen,open,setrefineSearchFilters,dbDropdown , setDbDropdown ,Sectors, setSectors,HeadquarterCityOptions,setHeadquarterCityOptions ,setMonthPickerValue ,refineSearchFilters , setSelectedInvestorHeadquartersCity , selectedInvestorHeadquartersCity , SwitchState ,InvestorOptions , setInvestorOptions ,
        lAdvisorsOptions,setlAdvisorsOptions,tAdvisorsOptions , settAdvisorsOptions , TargetCompanyOptions , setTargetCompanyOptions ,} = useContext(PeFilterContext);
    const { setCurrentPage } = useContext(TableDataContext);
    const [select, setSelected] = useState(0);
    const { getToken } = useContext(UserContext);
    const location = useLocation()
    const navigate = useNavigate();
    const { pathname } = useLocation()
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false
    const isFromSearchedList = ()=> refineSearchFilters[location?.state?.prevPath]?.globalSearch?.length > 0 ? true : false

console.log(selectedInvestorHeadquartersCity , "bhkb")

    //local updated options
    const [IndustriesOptions, setIndustriesOptions] = useState([])
    const [SectorOptions, setSectorOptions] = useState([])
    const [StageOptions, setStageOptions] = useState([])
    const [RoundOptions, setRoundOptions] = useState([])
    const [InvestorTypeOptions, setInvestorTypeOptions] = useState([])
    const [HeadquartersOptions, setHeadquartersOptions] = useState([])
    const [FirmTypeOptions, setFirmTypeOptions] = useState([])
    const [DealRange, setDealRange] = useState({ from: "", to: "" })
    const [invDealRange, setInvDealRange] = useState({ from: "", to: "" })
    const [TagSwitchState, setSwitchState] = useState(true)
    const [TagsOptions, setTagsOptions] = useState([])
    const [CityOptions, setCityOptions] = useState([])
    const [MinDealOptions, setMinDealOptions] = useState([])
    const [MaxDealOptions, setMaxDealOptions] = useState([])
    // setSelectedInvestorHeadquartersCity(HeadquarterCityOptions?.filter(option => option.isChecked))
    // setSelectedInvestorHeadquartersCity(HeadquarterCityOptions?.filter(option => option.isChecked))
    // console.log(HeadquarterCityOptions?.filter(option => option.isChecked) ,"ljhkjh" )

    const applyFilter = () => {
        const selectedIndustries = IndustriesOptions.filter(industry => industry.isChecked);
        const selectedSectors = Sectors.filter(option => option.isChecked);
        const selectedStages = StageOptions.filter(option => option.isChecked);
        const selectedRounds = RoundOptions.filter(option => option.isChecked);
        console.log(selectedRounds, 'rounds')
        const selectedCity = CityOptions?.filter(option => option.isChecked);
        const selectedInvestorType = InvestorTypeOptions?.filter(option => option.isChecked);
        const selectedHeadCity = HeadquarterCityOptions?.filter(option => option.isChecked);
        const selectedHead = HeadquartersOptions?.filter(option => option.isChecked);
        const selectedHeadNames = selectedHeadCity.map(option => option.name);
        const selectedFirm = FirmTypeOptions?.filter(option => option.isChecked);
        setSelectedInvestorHeadquartersCity(HeadquarterCityOptions?.filter(option => option.isChecked))
        const selectedOperators = SwitchState;
        const selectedlAdvisors = lAdvisorsOptions?.filter(option => option.isChecked);
        const selectedtAdvisors = tAdvisorsOptions?.filter(option => option.isChecked);
        const selectedTargetCompany = TargetCompanyOptions?.filter(option => option.isChecked); 
        const selectedInvestor = InvestorOptions?.filter(option => option.isChecked); 

        console.log(selectedHeadCity , "bhkb" , selectedHead)
        
        const invMin = MinDealOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        const invMax = MaxDealOptions?.filter(option => option.isChecked)?.at(0) ?? null;
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {
                ...prev[customPathname],
                industry: selectedIndustries,
                sector: selectedSectors,
                stage: selectedStages,
                round: selectedRounds,
                city: selectedCity,
                investorType: selectedInvestorType,
                investorHeadquarters: selectedHead,
                investor_city:selectedHeadCity,
                firmType: selectedFirm,
                dealRange: DealRange?.from != "" && DealRange?.to != "" ? [{ id: 0, name: DealRange?.from }, { id: 1, name: DealRange?.to }] : [],
                operator: TagSwitchState ? 1 : 0,
                tags: TagsOptions,
                deals_operator : selectedOperators,
                ladvisors: selectedlAdvisors,
                tadvisors: selectedtAdvisors,
                investor_id : selectedInvestor ,
                target_company:selectedTargetCompany,
                dealInvestorRange: invDealRange?.from != "" && invDealRange?.to != "" ? [{ id: 0, name: invDealRange?.from }, { id: 1, name: invDealRange?.to }] : []
            },
        }))
        console.log(refineSearchFilters , "khk", selectedHeadCity)
    }

    const resetFilter = () => {
        
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {}
        }))
        setHeadquarterCityOptions(HeadquarterCityOptions.map(option => {
            if (option.isChecked) {
                return { ...option, isChecked: false };
            }
            return option;
        }));
        setlAdvisorsOptions([]);
        settAdvisorsOptions([]);
        setTargetCompanyOptions([]);
        setInvestorOptions([]);
        setSectors([]);
        
        console.log(refineSearchFilters , "hkjh")
    }


    // Submitting Refine Search Form 
    const refineSearchFilter = (event) => {
        if(isMobile){
            setOpen(!open);
        }
        setrefineSearchFilters(prev => ({
            ...prev,
            [customPathname]: {globalSearch:[],
            }
        }))
        if(isFromGlobalSearch() || isFromSearchedList()){
            setMonthPickerValue(Constants.initialMonthRange)
        }
        event.preventDefault();
        setCurrentPage(1);
        applyFilter();
        setSelected(false)
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }

    }

    const onResetClick = (event) => {
        event.preventDefault();
        setCurrentPage(1);
        resetFilter()
        if (location.pathname != customPathname) {
            navigate(customPathname)
        }
    }

    const accordionClick = (val) => {
        if (val === select) {
            setSelected(0);
        } else {
            setSelected(val);
        }
    };

    // useEffect(() => {
    //     const ids = IndustriesOptions.filter(industry => industry.isChecked)?.map(option => option.id);

    //     if (ids.length > 0) {
    //         axios.post(filterSectorByIndutry, {
    //             "industry": ids,
    //             category: "investments"
    //         }, {
    //             headers: {
    //                 "Authorization": getToken()
    //             }
    //         }).then(response => {
    //             if (response.status === 200) {
    //                 setSectors(response.data?.data ?? [])
    //             } else {
    //                 setSectors([])
    //             }
    //         }).catch(onErrorHandler)
    //     } else {
    //         setSectors([])
    //     }

    // }, [IndustriesOptions])

    const getSearchTags = async (searchText, type) => {
        try {
        const response = await axios.post(tagList, {
            "tag": searchText,
            "type"    : type

        }, {
            headers: {
            "Authorization": getToken()
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return [];
        }
        } catch (error) {
            return [];
        }
    }


    const fetchData = async () => {
        const tags = await getSearchTags("ALL", "noncompetitor");
        const competitorTags = await getSearchTags("ALL", "competitor")
        console.log(tags, competitorTags, 'promi')
        openTagModal(tags, competitorTags)
    };
    
    const  [showDefinitionModal, setShowDefinitionModal] = useState(false);
    const [defValues, setDefValues] = useState({})
    console.log(defValues, 'showValues');

    const openTagModal = ( non_comp, comp) => {
        setShowDefinitionModal(true);
        setDefValues({
            non_comp: non_comp,
            comp: comp
        })
    }

    const defCloseHandler = () =>{
        setShowDefinitionModal(false);
        setDefValues({})
    }

    const toggleSwitchState = () => {
        setSwitchState(prevState => prevState === "and" ? "or" : "and");
    };
    const handleDbToggle = () => {
        setDbDropdown(!dbDropdown);
    };
    return (
        <div className="h-screen">
            <div className={isFromGlobalSearch() ?"flex flex-col overflow-y-auto no-scrollbar h-[70%]":"flex flex-col overflow-y-auto no-scrollbar lg:h-[62%] xl:h-[68%] 2xl:h-[75%] fixedFooter"}>
                <form onSubmit={refineSearchFilter} onReset={onResetClick}>
                    <main className="bg-[#FAF5EA] w-full">
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333]  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px] `}>
                        <div className="flex flex-col">
                            <div className="flex justify-between px-3 items-center pt-0">
                    <div className="flex items-end gap-2">
                                    <p className="text-[#333333] text-[15px] leading-[17px] tracking-[-0.3px]">Tag</p>                        
                        <span className="cursor-pointer" onClick={() => {fetchData()}
                        }>
                            <Tooltip title='Tag List'>
                                    <FaInfoCircle size={12} color="grey"/>
                            </Tooltip>
                        </span>
                    </div>
                                <Switch
                                    SwitchState={TagSwitchState}
                                    setSwitchState={setSwitchState}
                                    customPathname={customPathname} />
                            </div>
                            <div className="border-b-[3px] border-[#E8E6E1] bg-white">
                                <div className=" top-0 bg-white m-3 input-search relative pointer-events-auto border border-[#BC8B20]  rounded  py-0.5 opacity-[0.6]">
                                    <label className=" flex  rounded ">
                                        <Tags
                                            TagsOptions={TagsOptions}
                                            customPathname={customPathname}
                                            setTagsOptions={setTagsOptions}
                                            getSearchTags={getSearchTags} />
                                    </label>
                                </div>
                            </div>
                        </div>
                            <IndustryFilter
                                IndustriesOptions={IndustriesOptions}
                                setIndustriesOptions={setIndustriesOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(1)}
                                open={select === 1} />
                            <SectorFilter
                                setOptions={setSectors}
                                options={Sectors}
                                label="Sector"
                                accordionClick={() => accordionClick(2)}
                                customPathname={customPathname}
                                open={select === 2} />
                            <StageFilter
                                StageOptions={StageOptions}
                                customPathname={customPathname}
                                setStageOptions={setStageOptions}
                                accordionClick={() => accordionClick(3)}
                                open={select === 3} />
                            <RoundFilter
                                RoundOptions={RoundOptions}
                                setRoundOptions={setRoundOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(4)}
                                open={select === 4} />
                            <InvestorTypeFilter
                                InvestorTypeOptions={InvestorTypeOptions}
                                setInvestorTypeOptions={setInvestorTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(14)}
                                open={select === 14} />
                            <InvestorHeadquartersFilter
                                HeadquartersOptions={HeadquartersOptions}
                                setHeadquartersOptions={setHeadquartersOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(15)}
                                setHeadquarterCityOptions={setHeadquarterCityOptions}
                                HeadquarterCityOptions={HeadquarterCityOptions}
                                open={select === 15} />
                            <FirmTypeFilter
                                FirmTypeOptions={FirmTypeOptions}
                                setFirmTypeOptions={setFirmTypeOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(16)}
                                dataPath="investments"
                                open={select === 16} />
                            <Suspense fallback={<div>Loading...</div>}>
                                <InvestorDealRangeFilter
                                    DealRange={DealRange}
                                    setDealRange={setDealRange}
                                    customPathname={customPathname}
                                />
                            </Suspense>
                            <Suspense fallback={<div>Loading...</div>}>
                                <InvestorDealFilter
                                    customPathname={customPathname}
                                    invDealRange = {invDealRange}
                                    setInvDealRange = {setInvDealRange} />
                            </Suspense>
                            <InvestorCityTag
                                CityOptions={CityOptions}
                                setCityOptions={setCityOptions}
                                customPathname={customPathname}
                                accordionClick={() => accordionClick(20)}
                                open={select === 20} />
                        </ul>
                        <ul  className='flex pt-3 text-[16px] pl-1 border-t-2 border-[#e8e6e1] tracking-[-.34px] leading-[19px] font-sans_semi_bold text-[#BC8B20] cursor-pointer ' onClick={handleDbToggle}>
                            <span className="mt-[2px]">
                                {dbDropdown? <IoMdArrowDropdown /> : <IoMdArrowDropright />}
                            </span>
                            <h1 className='font-bold text-[16px] ml-1 '>{customPathname.includes("investors") ? "SHOW INVESTORS BY" : customPathname.includes("companies") ? "SHOW DEALS BY" : "SHOW ADVISORS BY"}</h1>
                        </ul>
                        <ul className={`transition-max-h overflow-y-auto duration-500 ease-in-out ${dbDropdown ? 'max-h-[1000px]' : 'max-h-0 overflow-y-hidden'} text-[#333333] mb-3  font-sans_book_body text-[14px] leading-[17px] tracking-[-0.3px]`}>
                                {/* <div className="py-1">
                                    <ToggleButton value={SwitchState} onLabel="AND" offLabel="OR" onToggle={toggleSwitchState} />
                                </div> */}

                                <Investor 
                                    options={InvestorOptions}
                                    setOptions={setInvestorOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(32)}
                                    label="Investor"
                                    open={select === 32}
                                />

                                <TargetCompany 
                                    options={TargetCompanyOptions}
                                    setOptions={setTargetCompanyOptions}
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(31)}
                                    label="Company"
                                    open={select === 31}
                                />

                                <LAdvisor 
                                    options={lAdvisorsOptions}
                                    setOptions={setlAdvisorsOptions}
                                    customPathname={customPathname}
                                    label="Legal Advisors"
                                    accordionClick={() => accordionClick(21)}
                                    open={select === 21}
                                />

                                <TAdvisor 
                                    options={tAdvisorsOptions}
                                    setOptions={settAdvisorsOptions}
                                    label="Transaction Advisors"
                                    customPathname={customPathname}
                                    accordionClick={() => accordionClick(29)}
                                    open={select === 29}
                                />

                        </ul>
                        <div className="px-3 py-2 flex space-x-4 bg-[#FAF5EA] fixed w-60 bottom-0">
                            <button type="reset" className="w-[60px] h-[28px] border-2 border-[#B8B8B8] rounded-[3px] text-[#666666] text-[13px] font-sans_book_body leading-[17px] tracking-[-0.3px]">
                                Reset
                            </button>
                            <button onClick={(select)} type="submit" className="bg-[#BC8B20] w-[145px] h-[28px] text-[#FFFFFF] text-[13px] rounded-[3px] font-sans_medium_body leading-[17px] ">
                                Filter
                            </button>
                        </div>
                    </main>
                    {/* reset button */}
                </form>
            </div>
            {showDefinitionModal && <DefinitionModal values={defValues} onClose={defCloseHandler}/>}
        </div>
    );
}
