import React, { useState, useContext, useEffect } from "react";
import MonthPickerComponent from "../../Utils/MonthPickerComponent";
import xls from "../../images/xls/png/Group 47.png";
import line from "../../images/line.png";
import FileIcon from "../../images/file.png";
import FileIconBlur from "../../images/fileblur.png";
import MainMenu from '../../Pages/RE/ReMainMenu'
import Card from '../../Components/RE//ReListCard/Card'
import Table from '../../Components/RE/TableComponent'
import RefineSearch from '../../Components/RE/RefineSearchComponent'
import ReLayout from '../../Components/ReLayout'
import Constants from '../../Constants'
import ReInvestmentRefineSearch from '../../Components/RefineSearch/ReInvestmentRefineSearch'
import SavedFilterContent from "../../Components/SavedFilter";
import ExportPopup from "../../Components/ExportPopup";
import {reInvestmentExportData } from "../../Components/ExportPopup/ExportData"
import { reExportPDF } from "../../Config/config";
import { useLocation } from "react-router";
import { PeFilterContext } from "../../Contexts/PeFilterContext";
import FilterTag from "../../Components/Tag";
import { UserContext } from "../../Contexts/UserContext";
import ExcelDownloader from "../../Components/shared/ExcelDownloader";
import { TableDataContext } from "../../Contexts/TableDataContext";

/**
 * The `REHome` function is a React component that renders a page for real estate investments,
 * including a filter section, a table of investments, and a sidebar for refining search options.
 * @param props - The `props` parameter is an object that contains the properties passed to the
 * `REHome` component. These properties can be accessed using dot notation, such as `props.active` to
 * access the `active` property.
 * @returns The REHome component is being returned.
 */
function REHome(props) {
    const [showPopup, setShowPopup] = useState(false);
    const { pathname } = useLocation(); 
    const { save, setSave, showMonthPicker,refineSearchFilters ,groupId} = useContext(PeFilterContext);
    const {globalSearchResult} = useContext(TableDataContext);
    const [openTab, setOpenTab] = React.useState(1);
    const savedList = () => {
        setSave(!save)
    }
    useEffect(() => {
        setOpenTab(props.active) 
    }, [openTab])
    const {isStudent,isTrialExport} = useContext(UserContext) 
    const location = useLocation(); 
    const isFromGlobalSearch = ()=> refineSearchFilters[pathname]?.globalSearch?.length > 0 ? true : false

    return (
        <>
            <ReLayout>
                <div className="flex min-h-screen h-screen flex-col">
                    { isFromGlobalSearch() ? <></> :<Card />}
                    <div className="flex min-h-0 flex-1 overflow-hidden">                        
                        <main className={`min-w-0 flex-1 md:border-t border-gray-200 flex ${isFromGlobalSearch() ? 'mt-[4.25rem] md:mt-[3rem]':''}`}>
                            <section className="flex h-full min-w-0 flex-1 flex-col lg:order-last bg-[#EDEDED]">
                                <FilterTag />
                                <div className="pl-3 pr-4 lg:pr-4 md:pr-[0.7rem]">
                                    <div className=" ">
                                        <div className="px-2  md:px-3 rounded-[6px] rounded-b-none bg-white block lg:flex flex-row justify-between ">

                                            <MainMenu active={1} menu="re" subMenu="re-investment" primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                                subTypeMasterId={Constants.primaryMasterSubIds.re_investments_id}
                                                typeId={Constants.moduleIds.companiesId} />

                                                {isFromGlobalSearch() ? globalSearchResult?.company?.investment_count !==0 ? 
                                                <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                                                    <label htmlFor="" className="text-[#666666] ">
                                                    <div className={showMonthPicker!=0 ?"":'flex'}>
                                                            <MonthPickerComponent />
                                                        </div>
                                                    </label>                                               
                                                    <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                                                    {isStudent == 'true' ? <></>  : 
                                                    <>
                                                                <button> 
                                                                    {
                                                                    isTrialExport=='false' ?
                                                                        <ExcelDownloader
                                                                        url={reExportPDF}
                                                                        fileName={Constants.exportNames.re_company_investment}
                                                                        data={{"type" : "re-investments"}}  
                                                                        children={<img
                                                                            src={xls}
                                                                            className="max-h-[24px] mt-1 max-w-[21px]"
                                                                            alt=""
                                                                        />}                   
                                                                        />
                                                                        :

                                                                        <img
                                                                        src={xls}
                                                                        className="h-[24px] mt-1 max-w-[21px]"
                                                                        alt=""
                                                                        onClick={() => setShowPopup(!showPopup)}
                                                                    />
                                                                    }
                                                                    
                                                                    {showPopup && (
                                                                        <ExportPopup
                                                                            modules="re-company"
                                                                            fileName={Constants.exportNames.re_company_investment}
                                                                            url={reExportPDF}
                                                                            onClose={() => setShowPopup(false)}
                                                                            exportOptions={reInvestmentExportData}
                                                                            type="re-investments"
                                                                            />
                                                                    
                                                                    )}
                                                                </button>
                                                        
                                                                  <a><img className="h-[24px] max-w-[2px] mt-1 " src={line} /></a>
                                                        </>

                                                        }

                                                         {groupId?.myList?.length >0 ? <></>:
                                                            <a className="group cursor-pointer">
                                                            <div className="cursor-pointer" onClick={savedList}>
                                                                <img src={save ? FileIcon : FileIconBlur}
                                                                    className="max-h-[24px] mt-1 max-w-[21px]"
                                                                    alt="" />
                                                            </div>
                                                        </a>
                                                        }
                                                    </div>                                                
                                                </div>
                                                : <></> :
                                                <div className={"justify-between items-center px-2 py-2 space-x-4 "+(showMonthPicker!=0 ? 'md:flex':'flex')}>
                                                    <label htmlFor="" className="text-[#666666] ">
                                                    <div className={showMonthPicker!=0 ?"":'flex'}>
                                                            <MonthPickerComponent />
                                                        </div>
                                                    </label>                                               
                                                    <div className={showMonthPicker!=0 ?"hidden md:flex space-x-3":"flex space-x-3"}>   
                                                    {isStudent == 'true' ? <></>  : 
                                                    <>
                                                                <button> 
                                                                    {
                                                                    isTrialExport=='false' ?
                                                                        <ExcelDownloader
                                                                        url={reExportPDF}
                                                                        fileName={Constants.exportNames.re_company_investment}
                                                                        data={{"type" : "re-investments"}}  
                                                                        children={<img
                                                                            src={xls}
                                                                            className="max-h-[24px] mt-1 max-w-[21px]"
                                                                            alt=""
                                                                        />}                   
                                                                        />
                                                                        :

                                                                        <img
                                                                        src={xls}
                                                                        className="h-[24px] mt-1 max-w-[21px]"
                                                                        alt=""
                                                                        onClick={() => setShowPopup(!showPopup)}
                                                                    />
                                                                    }
                                                                    
                                                                    {showPopup && (
                                                                        <ExportPopup
                                                                            modules="re-company"
                                                                            fileName={Constants.exportNames.re_company_investment}
                                                                            url={reExportPDF}
                                                                            onClose={() => setShowPopup(false)}
                                                                            exportOptions={reInvestmentExportData}
                                                                            type="re-investments"
                                                                            />
                                                                    
                                                                    )}
                                                                </button>
                                                        
                                                                  <a><img className="h-[24px] max-w-[2px] mt-1 " src={line} /></a>
                                                        </>

                                                        }

                                                         {groupId?.myList?.length >0 ? <></>:
                                                            <a className="group cursor-pointer">
                                                            <div className="cursor-pointer" onClick={savedList}>
                                                                <img src={save ? FileIcon : FileIconBlur}
                                                                    className="max-h-[24px] mt-1 max-w-[21px]"
                                                                    alt="" />
                                                            </div>
                                                        </a>
                                                        }
                                                    </div>                                                
                                                </div>
                                            }
                                            
                                        </div>
                                        {save ? <SavedFilterContent
                                            primaryTypeMasterId={Constants.primaryMasterIds.reId}
                                            subTypeMasterId={Constants.primaryMasterSubIds.re_investments_id}
                                            typeId={Constants.moduleIds.companiesId}
                                            show={save} /> : ''}


                                    </div>

                                </div>
                                <div className='overflow-y-auto'>
                                    <Table menu="re" page="list" sub_type_master_id={Constants.subCategoryIds.re_investments_id}/>
                                </div>
                            </section>
                            {/* Secondary column (hidden on smaller screens) */}
                            <aside className="order-first block flex-shrink-0">
                                <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                    {/* Your content */}
                                    <RefineSearch >
                                        <ReInvestmentRefineSearch customPathname={location.pathname}/>
                                    </RefineSearch>
                                </div>
                            </aside>
                        </main>
                    </div>


                </div>
            </ReLayout>
        </>
    )
}

export default REHome
