import React from 'react'
import Layout from '../../Components/InvestorLayout'
 import InvestorCompanyDetail from '../../Components/Investor/InvestorDetailPage/InverstorCompanyDetail'
 import RefineSearch from '../../Components/RefineSearchComponent'
 import InvestorCard from '../../Components/Investor/InvestorDetailPage/InvestorDetailCard'
 import ChatIcon from '../../images/Group 13/png/Group 13.png';
import chatPopupIcon from '../../images/chatPopupIcon.png'
import ChatPopup from '../../Components/ChatPopup'
import { INVESTOR_COMPANY_PROFILE, INVESTOR_CARD_QUERY } from '../../Queries/Pe'
import useModule from '../../hooks/useModule'
import InvestorBackedIpoRefineSearch from '../../Components/RefineSearch/InvestorBackedIpoRefineSearch'
import Constants from '../../Constants'
import { routeNames } from '../../routeSegments'
/**
 * The InvestorIpoDetailPage function is a React component that renders a page displaying IPO details
 * for investors, including a card component, a company detail component, and a refine search
 * component.
 * @returns The InvestorIpoDetailPage component is returning a JSX element.
 */
function InvestorIpoDetailPage() {
    const [showModal, setShowModal] = React.useState(false);
    const {primaryMasterId} = useModule();
    return ( 
        <div>
            <Layout>
            <div className="flex h-screen flex-col">
                    {/* <Card DEATAILPAGE_CARD={DEATAILPAGE_CARD}/> */}
                    <InvestorCard page="ipos"/>
                    <div className="flex min-h-0 flex-1 overflow-hidden">
                        {/* Primary column */}
                        <section
                            aria-labelledby="primary-heading"
                            className="flex h-full min-w-0 flex-1 flex-col overflow-y-auto lg:order-last bg-[#EDEDED]">
                            {/* <CompanyDetail COMPANY_PROFILE={COMPANY_PROFILE}  /> */}
                            <InvestorCompanyDetail  page="ipos" type="PE-VC Backed IPOs"/>
                            
                        {/* <Outlet /> */}
                        {/* Your content */}
                        </section>
                        {/* Secondary column (hidden on smaller screens) */}
                        <aside className="order-first block flex-shrink-0">
                            <div className="relative flex h-full  flex-col  border-r border-gray-200 bg-[#FAF5EA]">
                                {/* Your content */}
                                <RefineSearch>
                                    <InvestorBackedIpoRefineSearch customPathname={primaryMasterId === Constants.primaryMasterIds.peId
                                        ? routeNames.pe_investors_backed_ipos_pe_vc_investments
                                        : routeNames.vc_investors_backed_ipos_vc_investments} />
                                </RefineSearch>
                            </div>
                        </aside>
                    </div>
                    <br/>
                    
                </div>
            </Layout>
        </div>
    )
}

export default InvestorIpoDetailPage